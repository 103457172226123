export const STORE_DATA = "STORE_DATA"
export const STORE_USER = "STORE_USER"
export const STORE_USER_ID = "STORE_USER_ID"
export const STORE_USER_LOGOUT = "STORE_USER_LOGOUT"
export const USER_LOGGED_IN = "USER_LOGGED_IN"
export const RESULTS_PAGE = "RESULTS_PAGE"
export const RESULTS_PAGE_AM = "RESULTS_PAGE_AM"
export const USER_DATA_STORED = "USER_DATA_STORED"
export const USER_DATA_UPDATED = "USER_DATA_UPDATED"

export const STORE_FONT = "STORE_FONT"
export const STORE_FONT_BOLD = "STORE_FONT_BOLD"
export const STORE_FONT_ROBOTO = "STORE_FONT_ROBOTO"

export const STORE_PDF_PARAMS = "STORE_PDF_PARAMS"