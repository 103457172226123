<template>
  <div id="app" :class="touch">
    <cabinet-buttons></cabinet-buttons>
    <router-view></router-view>
  </div>
</template>

<script>

import cabinetButtons from './components/cabinetButtons';

export default {
  name: "app",
  metaInfo() {
    return {
      title: "Інтерактивні тести про бізнес",
      meta: [
        { name: 'description', content:  'Структуруйте свій проект разом з командою за допомогою простих та цікавих тестів'},
        { property: 'og:title', content: "Інтерактивні тести про бізнес"},
        { property: 'og:site_name', content: 'LvBS Quiz'},
        { property: 'og:type', content: 'website'},
        { name: 'robots', content: 'index,follow'}
      ]
    }
  },
  components: {
    cabinetButtons: cabinetButtons
  },
  data() {
    return {
        touch: ''
    }
  },
  methods: {
    mobileDetect(){
      if(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)){
        this.touch = 'touch';
        return true;
      } else{
        this.touch = 'desktop';
      }
    },
  },
  mounted: function () {
    this.mobileDetect();
  },
};
</script>
