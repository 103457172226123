<template>
	<div class="results-wrapper" v-if="sections && answers.length" :class="pdfSettingsModal? 'opened-modal': ''">

			<template v-for="(section, sIndex) in sections" >
				<div class="results-item" :key="'section'+sIndex" :class="[(sIndex === 2 || sIndex === 5) ? 'bg3' : (sIndex % 2 === 0) ? 'bg1' : 'bg2', openedMobile[sIndex]? 'opened' : 'closed']">
					<div class="result-item-index" v-if="!isMobile">
            {{'0' + (sIndex + 1)}}
          </div>
          <div class="results-header">
						<h3 class="results-title orange" :key="'section'+sIndex" @click="openAnswers(sIndex)">{{section.question_title}}</h3>
            <svg v-if="isMobile" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 1L7 7L1 0.999996" stroke-width="2" stroke-linecap="round"/>
            </svg>
					</div>

          <transition name="fade">
          <div class="results-content"  v-if="!isMobile || isMobile && openedMobile[sIndex]">
            <div class="sub-question" :key="'question' + qIndex" v-for="(question, qIndex) in section.sub_questions">
              <h4 class="white">{{question}}</h4>
              <textarea-autosize :maxlength="maxSymbols" placeholder="Введіть вашу відповідь"
                                 @change.native="editAnswer()" :class="answers[sIndex].answers_array[qIndex].value === ''? 'empty-answer' : ''"
                                 v-model="answers[sIndex].answers_array[qIndex].value">
                                 v-model="answers[sIndex].answers_array[qIndex].value">
              </textarea-autosize>
            </div>

          </div>
          </transition>
          </div>
			</template>

    <div class="results-btn" @click="openModal()" v-if="!isMobile">
      <span>Бізнес<br>дизайн</span>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 20V16H20V20H4V16H2V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20Z" fill="white"/>
        <path d="M12 14.9294L12 3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.9531 11L11.9767 14.9765L8.00018 11" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>

    <template v-if="pdfSettingsModal && !isMobile">
      <transition name="fade">
        <div class="modal-overlay">
          <div class="modal-cross" @click="closePdfModal()">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 2L17 17M17 2L2 17" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </div>
          <h1 class="modal-title">{{modalTitle}}</h1>
          <div class="modal orange-modal modal--stretch">
            <ResultsButtons></ResultsButtons>
          </div>
        </div>
      </transition>
    </template>

    <ResultsButtons class="results-buttons-mobile" :modal-title="modalTitle" v-if="isMobile"></ResultsButtons>

    <template v-if="answersEmptyModal">
      <transition name="fade">
        <div class="modal-overlay success-modal">
          <div class="modal orange-modal">
            <p>Для отримання результатів тесту дайте відповіді на запитання</p>
            <button type="submit" @click="closeAnswersEmptyModal" class="button button-desktop">ок</button>
          </div>
        </div>
      </transition>
    </template>

	</div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import { mixins } from '@/mixins';
  import { results } from '@/results';
  import { EventBus } from './bus.js';
	const PDFDocument = require('pdfkit/js/pdfkit.standalone.js');
  import ResultsButtons from './parts/ResultsButtons';
	const blobStream  = require('blob-stream');
	export default {
        data() {
          return {
            activeSection: '',
            maxSymbols: 500,
            activeSubsection: null,
            buttonActive: false,
            answersEmptyModal: false,
            pdfSettingsModal: false,
            openedMobile : [],
            modalTitle: 'Надрукувати мою бізнес модель',
            scale: 0,
            generateInProgress : false,
          }
		},
		mixins: [mixins, results],
		computed: {
			...mapGetters({
				sections: 'questions',
				userId: 'userId',
        font: 'font',
        fontBold: 'fontBold',
				fontRoboto: 'fontRoboto',
        pdfParams: 'pdfParams',
      }),
		},
    components: {
      ResultsButtons: ResultsButtons
    },
		beforeMount() {
      this.$store.dispatch('logged', true);
			this.$store.dispatch('results', true);
			this.$store.dispatch('getUserData')
				.then(() => {
	        		this.$store.dispatch('getUserAnswers', 'usersAnswers')
	        			.then(data => {
	          				this.$store.dispatch('getBdQuestions')
								.then(() => {
									this.prepareData(data);
									if ( data.hasOwnProperty('userQuestion') && data.hasOwnProperty('userSection') ) {
										this.activeSection = data.userSection;
										this.activeSubsection = data.userQuestion;
									}

                  for(let i = 0; i < this.sections.length; i++){
                    this.openedMobile[i] = false;
                  }

									this.$store.dispatch('loadFont');
									this.$store.dispatch('loadFontBold');
									this.$store.dispatch('loadFontRoboto');

                  this.checkAnswersNumber();
			            		})
			            })
			    });
			EventBus.$on('store', () => {
				const that = this;
				this.$store.dispatch('storeUserData', { userId: that.userId, userAnswers: that.answers, query: `/usersAnswers/${that.userId}`, userSection: that.activeSection, userQuestion: that.activeSubsection })
					.then(() => {
						EventBus.$emit('dataStored');
					})
			});
		},
    mounted() {
      EventBus.$on('goToFirstQuestion', data => {
        this.goToQuestion()
      });

      EventBus.$on('closePdfModal', data => {
        this.closePdfModal();
      });

      EventBus.$on('pdf-generate-bd',  this.generatePDF);
    },
    beforeDestroy () {
      EventBus.$off('pdf-generate-bd', this.generatePDF)
    },
		methods: {
      checkAnswersNumber(){
        let changed = false;

        for(let i = 0; i < this.sections.length; i++){
          let subQuestions = this.sections[i].sub_questions, subQuestionsAnswers = this.answers[i].answers_array;

          if(subQuestionsAnswers.length < subQuestions.length){
            for(let j = 0; j < subQuestions.length; j++){
              if(!subQuestionsAnswers[j]){
                changed = true;
                this.$set(this.answers[i].answers_array, j, {'value': ''})
              }
            }
          }
          else if(subQuestionsAnswers.length > subQuestions.length){
            changed = true;
            let difference = subQuestionsAnswers.length - subQuestions.length,  start = subQuestions.length - difference;
            this.answers[i].answers_array.splice(start, difference);
          }
        }

        if(changed){
          EventBus.$emit('store');
        }
      },
      openAnswers(i) {
        this.$set(this.openedMobile, i, !this.openedMobile[i])
      },

      goToQuestion(){
          this.$store.dispatch('storeUserData', { userId: this.userId, userAnswers: this.answers, query: `/usersAnswers/${this.userId}`, userSection: 'q_1', userQuestion: 0 })
              .then(() => {
                EventBus.$emit('dataStored');
              })
          this.$router.push({ name: 'business-design' }).catch(err => {});
      },

      openModal(){
        if(this.allAnswersEmpty(this.answers)){
          this.answersEmptyModal = true;
          this.pdfSettingsModal = false;
        } else{
          this.pdfSettingsModal = true;
          this.answersEmptyModal = false;
        }

      },

      closePdfModal(){
        this.pdfSettingsModal = false;
      },

			closeAnswersEmptyModal(){
				this.answersEmptyModal = false;
			},

      editAnswer() {
        this.$store.dispatch('storeUserData', { userId: this.userId, userAnswers: this.answers, query: `/usersAnswersAM/${this.userId}` });
        EventBus.$emit('dataStored');
      },

      async generatePDF() {
        if (!this.generateInProgress) {
          if (this.allAnswersEmpty(this.answers)) {
            this.answersEmptyModal = true;
            this.generateInProgress = true;
          } else {
            this.scale = this.pdfParams.size === 'A3' ? 1.414 : 1;
            const doc = new PDFDocument({margin: 0, size: this.pdfParams.size, layout: 'landscape'});
            doc.registerFont('Proba', this.font);
            doc.registerFont('ProbaBold', this.fontBold);
            doc.registerFont('Roboto', this.fontRoboto);
            let bg = await this.toDataURL((this.pdfParams.color === 'color') ? 'pictures/pdf_color.png' : 'pictures/pdf.png');
            let bg2 = await this.toDataURL((this.pdfParams.color === 'color') ? 'pictures/pdf_color2.png' : 'pictures/pdf2.jpg');
            let qr = await this.toDataURL('pictures/qr.jpg');
            doc
                .image(bg2, 0, 0, {width: 842 * this.scale, height: 596 * this.scale});
            doc
                .image(bg, 0, 0, {width: 842 * this.scale, height: 596 * this.scale});
            doc
                .image(qr, 774 * this.scale, 21 * this.scale, {
                  width: 40 * this.scale,
                  height: 40 * this.scale
                });
            doc
                .font('Proba')
                .fontSize(18 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0D0C0A')
                .fillOpacity((this.pdfParams.color === 'color') ? 0.9 : 0.5)
                .text('ТВІЙ\nБІЗНЕС\nДИЗАЙН', 32 * this.scale, 29 * this.scale, {
                  width: 193 * this.scale,
                  characterSpacing: .5,
                  lineGap: 1.2

                })
                .font('Roboto')
                .fontSize(48 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0D0C0A')
                .fillOpacity((this.pdfParams.color === 'color') ? 0.9 : 0.9)
                .text('МІЙ\nКЛІЄНТ', 85 * this.scale, 134 * this.scale, {
                  width: 220 * this.scale,
                  characterSpacing: 2,
                  fontWeight: 400,
                })

                .font('Proba')
                .fontSize(14 * this.scale)
                .text(`${this.prepareAnswers(this.answers[0])}`, 85 * this.scale, 264 * this.scale, {width: 270 * this.scale})
            doc
                .font('Proba')
                .fontSize(14 * this.scale)
                .fillOpacity(1)
                .fillColor((this.pdfParams.color === 'color') ? '#FF8200' : '#0d0c0a')
                .text(this.sections[1].question_title.toUpperCase(), 395 * this.scale + 36 * this.scale, 30 * this.scale, {
                  width: 140 * this.scale,
                  height: 106 * this.scale,
                  characterSpacing: 1,
                  lineGap: 1.2,
                })
                .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
                .fontSize(9 * this.scale)
                .text(`${this.prepareAnswers(this.answers[1])}`, 395 * this.scale + 36 * this.scale, 79 * this.scale, {
                  width: 140 * this.scale,
                  height: 106 * this.scale
                });
            doc
                .font('Proba')

                .fillOpacity(1)
                .fontSize(14 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#FF8200' : '#0d0c0a')
                .text(this.sections[2].question_title.toUpperCase(), 615 * this.scale + 54 * this.scale, 45 * this.scale + 68 * this.scale, {
                  width: 120 * this.scale,
                  height: 106 * this.scale,
                  characterSpacing: 1,
                  lineGap: 1.2,
                })
                .fontSize(9 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
                .text(`${this.prepareAnswers(this.answers[2])}`, 615 * this.scale + 54 * this.scale, 80 * this.scale + 68 * this.scale, {
                  width: 140 * this.scale,
                  height: 106 * this.scale
                });
            doc
                .font('Proba')
                .fillOpacity(1)
                .fontSize(14 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#FF8200' : '#0d0c0a')
                .text(this.sections[3].question_title.toUpperCase(), 385 * this.scale + 35 * this.scale, 225 * this.scale + 48 * this.scale, {
                  width: 140 * this.scale,
                  height: 106 * this.scale,
                  characterSpacing: 1,
                  lineGap: 1.2,
                })
                .fontSize(9 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
                .text(`${this.prepareAnswers(this.answers[3])}`, 385 * this.scale + 35 * this.scale, 260 * this.scale + 48 * this.scale, {
                  width: 140 * this.scale,
                  height: 106 * this.scale
                });
            doc
                .font('Proba')
                .fillOpacity(1)
                .fontSize(14 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#FF8200' : '#0d0c0a')
                .text(this.sections[4].question_title.toUpperCase(), 593 * this.scale + 52 * this.scale, 322 * this.scale + 45 * this.scale, {
                  width: 140 * this.scale,
                  height: 106 * this.scale,
                  characterSpacing: 1,
                  lineGap: 1.2,
                })
                .fontSize(9 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
                .text(`${this.prepareAnswers(this.answers[4])}`, 593 * this.scale + 52 * this.scale, 322 * this.scale + 80 * this.scale, {
                  width: 140 * this.scale,
                  height: 106 * this.scale
                });

            doc.font('Proba')
                .fillOpacity(.7)
                .fontSize(9 * this.scale)
                .fillColor((this.pdfParams.color === 'color') ? '#888B8D' : '#888B8D')
                .text('Designed & Developed by Bambuk', 660 * this.scale, 560 * this.scale, {
                      width: 177 * this.scale, link: 'https://bambukstudio.com/',
                      underline: false
                    },
                );
            doc
                .fillOpacity(1)
                .translate(34 * this.scale, 522 * this.scale)
                .scale(this.scale)
                .path('M26.0775 51.1533C40.2036 51.1533 51.655 39.7022 51.655 25.5766C51.655 11.4511 40.2036 0 26.0775 0C11.9514 0 0.5 11.4511 0.5 25.5766C0.5 39.7022 11.9514 51.1533 26.0775 51.1533Z')
                .fill((this.pdfParams.color === 'color') ? '#FF8200' : '#807F7F')
                .path('M41.0984 28.3887C41.0984 28.9879 40.954 29.5212 40.6661 29.9866C40.3105 30.5755 39.8327 30.9739 39.2338 31.1853C38.8236 31.3294 38.2626 31.4022 37.5527 31.4022H33.5918V29.9375H37.4028C37.9686 29.9375 38.4185 29.865 38.7514 29.7208C39.2729 29.4876 39.5335 29.094 39.5335 28.5392C39.5335 28.1059 39.3911 27.7314 39.1081 27.4156C38.8264 27.0997 38.124 26.6749 37.0032 26.1424C36.2938 25.8099 35.7881 25.5547 35.4887 25.3761C34.9332 25.0441 34.5181 24.6886 34.2399 24.3126C33.8634 23.7905 33.6746 23.2247 33.6746 22.6146C33.6746 21.9924 33.8239 21.4599 34.1243 21.0161C34.468 20.5058 34.9507 20.1508 35.5724 19.9512C35.9938 19.8177 36.4992 19.751 37.0866 19.751H40.5818V21.216H36.9857C36.5645 21.216 36.2369 21.2462 36.0042 21.3081C35.7709 21.369 35.5724 21.5436 35.4055 21.8313C35.2945 22.0094 35.2395 22.2139 35.2395 22.4475C35.2395 22.7912 35.3273 23.0855 35.5048 23.3297C35.6377 23.5187 35.8629 23.7126 36.1796 23.9119C36.4961 24.1118 36.9707 24.3612 37.6024 24.661C38.346 25.0159 38.867 25.2823 39.1673 25.4601C39.7547 25.8145 40.1934 26.181 40.4817 26.5586C40.8925 27.1135 41.0984 27.7231 41.0984 28.3887Z')
                .fill('#FFFFFF')
                .path('M32.7863 28.1052C32.7863 29.0266 32.5073 29.7814 31.9509 30.3688C31.2924 31.0568 30.3129 31.4015 29.0124 31.4015H26.9257V29.9367H28.9322C29.5334 29.9367 29.9887 29.8642 30.3026 29.7201C30.9156 29.4307 31.2205 28.8655 31.2205 28.0224C31.2205 27.4344 31.0264 26.9687 30.6354 26.6244C30.2468 26.28 29.7275 26.108 29.0817 26.108H26.926V24.6441H28.4992C29.0987 24.6441 29.6151 24.4937 30.0443 24.1942C30.4746 23.8948 30.6875 23.4676 30.6875 22.9136C30.6875 22.4027 30.516 21.9747 30.1717 21.6321C29.7834 21.2432 29.2618 21.0487 28.6074 21.0487C27.9866 21.0487 27.4642 21.2435 27.0428 21.6321C26.5655 22.0754 26.3273 22.6967 26.3273 23.4955V31.4017H24.7803L24.7961 23.4625C24.7961 22.3081 25.1433 21.3735 25.8363 20.6575C26.5299 19.9418 27.4095 19.584 28.475 19.584C29.6726 19.584 30.6219 19.9338 31.3209 20.6328C31.9417 21.2535 32.253 22.0023 32.253 22.8797C32.253 23.4901 32.1365 23.9891 31.9034 24.3775C31.7257 24.6882 31.4475 24.9488 31.0709 25.1596C31.6141 25.4372 32.0358 25.8342 32.3358 26.3502C32.6347 26.8654 32.7863 27.4517 32.7863 28.1052Z')
                .fill('#FFFFFF')
                .path('M23.6462 24.6777L21.3871 30.5514C21.2542 30.9058 21.1426 31.1336 21.0546 31.2329C20.8653 31.4549 20.5828 31.5662 20.2054 31.5662C19.9175 31.5662 19.6925 31.5073 19.5311 31.3908C19.3703 31.2754 19.2012 30.9944 19.0237 30.5514L16.7646 24.6777H18.3534L20.2051 29.5688L22.056 24.6777H23.6462Z')
                .fill('#FFFFFF')
                .path('M17.3181 31.4022H15.0838C14.5744 31.4022 14.1242 31.3625 13.7361 31.2854C13.3475 31.2074 12.9701 31.0466 12.6036 30.803C11.9937 30.3928 11.5666 29.8261 11.3226 29.105C11.1443 28.5837 11.0557 27.9843 11.0557 27.308V19.751H12.62V27.308C12.62 27.7288 12.6531 28.0731 12.721 28.3392C12.832 28.7724 13.0368 29.1271 13.336 29.4044C13.5912 29.638 13.8846 29.7945 14.218 29.8704C14.4176 29.9159 14.6952 29.9374 15.0505 29.9374H17.3179V31.4022H17.3181Z')
                .fill('#FFFFFF')
                .path('M22.8021 21.0832C22.3709 21.1557 21.9961 21.3375 21.6777 21.6315C21.2861 21.9948 21.0557 22.4798 20.9855 23.0842H19.4434C19.5133 22.1027 19.8547 21.2929 20.4715 20.6569C21.0977 20.0123 21.8744 19.6579 22.8024 19.5938V21.0832H22.8021Z')
                .fill('#FFFFFF');
            doc
                .translate(72 * this.scale, 4 * this.scale)
                .path('M11.1309 4.04078L11.1688 8.62293C14.7154 8.84554 16.8775 11.0531 17.1431 14.5222H25.9432C25.9432 6.80491 19.2293 -0.374415 11.1309 0.0151608V4.04078Z')
                .fill((this.pdfParams.color === 'color') ? '#FF8200' : '#4F4F4F')
                .path('M2.2887 14.5039L4.62151 14.5224C4.81117 11.0533 7.12501 8.79008 10.6716 8.60457L10.6337 3.7627C4.0715 3.7627 -0.157899 8.06658 0.012794 14.5039H2.2887Z')
                .fill('#807F7F')
                .path('M4.57144 15.0489L2.49512 15.0312C2.56264 19.5451 5.70245 22.6131 9.95638 22.7894L9.88886 20.603C6.78281 20.3738 4.82465 18.3108 4.57144 15.0489Z')
                .fill('#B3B3B3')
                .path('M17.5508 40.9768H20.8509C23.6768 40.9768 25.5923 39.233 25.5923 36.4689C25.5923 33.7048 23.6768 31.9609 20.8509 31.9609H17.5508V40.9768ZM23.7337 36.4689C23.7337 38.3055 22.5388 39.4927 20.8319 39.4927H19.3905V33.445H20.8319C22.5578 33.4265 23.7337 34.6323 23.7337 36.4689Z')
                .fill('#807F7F')
                .path('M28.5504 33.3698H31.8315V31.9414H26.7676V40.9758H31.9073V39.5474H28.5504V37.1728H31.4332V35.7444H28.5504V33.3698Z')
                .fill('#807F7F')
                .path('M38.8869 33.0349C38.1093 32.2743 37.0472 31.8105 35.8903 31.8105C34.0695 31.8105 33.0833 32.9978 33.0833 34.4819C33.0833 37.5243 37.2369 36.8565 37.2369 38.3777C37.2369 38.8971 36.7817 39.5464 35.8523 39.5464C35.1316 39.5464 34.4109 39.1383 33.8989 38.6374L32.7988 39.7134C33.6902 40.5853 34.7713 41.0862 36.023 41.0862C37.9576 41.0862 39.0576 39.8803 39.0576 38.3406C39.0576 35.3909 34.904 36.0031 34.904 34.4263C34.904 33.981 35.2454 33.3503 36.061 33.3503C36.6868 33.3503 37.3696 33.7213 37.7679 34.0923L38.8869 33.0349Z')
                .fill('#807F7F')
                .path('M40.4043 40.9758H42.1681V31.9414H40.4043V40.9758Z')
                .fill('#807F7F')
                .path('M48.1048 37.3574H49.7548V39.1939C49.2996 39.4351 48.7875 39.5464 48.1996 39.5464C46.4358 39.5464 45.203 38.2849 45.203 36.4484C45.203 34.6118 46.4358 33.3503 48.1996 33.3503C49.1289 33.3503 49.8876 33.6657 50.4186 34.1851L51.5376 33.0906C50.7031 32.2743 49.5272 31.8105 48.1048 31.8105C45.2788 31.8105 43.3633 33.6842 43.3633 36.4484C43.3633 39.2125 45.2788 41.0862 48.1048 41.0862C49.5082 41.0862 50.7031 40.6038 51.5376 39.8061V35.9475H48.1048V37.3574Z')
                .fill('#807F7F')
                .path('M52.8457 31.9414V40.9758H54.6285V35.2992L59.6924 40.9758H60.8114V31.9414H59.0286V37.6181L53.9647 31.9414H52.8457Z')
                .fill('#807F7F')
                .path('M17.4932 27.7308H20.2053C21.6846 27.7308 22.6898 26.8218 22.6898 25.3748C22.6898 24.3174 22.1019 23.5197 21.1915 23.2229C21.8364 22.8889 22.2157 22.2397 22.2157 21.3677C22.2157 20.0135 21.2863 19.1602 19.8829 19.1602H17.4932V27.7308ZM21.7605 25.3192C21.7605 26.3024 21.1157 26.9331 20.1674 26.9331H18.4604V23.7238H20.1674C21.1157 23.7238 21.7605 24.373 21.7605 25.3192ZM21.2863 21.4605C21.2863 22.3324 20.6984 22.9261 19.826 22.9261H18.4794V19.995H19.826C20.6984 19.995 21.2863 20.57 21.2863 21.4605Z')
                .fill('#807F7F')
                .path('M30.5984 24.6132C30.5984 26.0231 29.6501 26.9878 28.2656 26.9878C26.9001 26.9878 25.9708 26.0417 25.9708 24.6132V19.1777H25.0225V24.6132C25.0225 26.5426 26.3311 27.8226 28.2656 27.8226C30.2002 27.8226 31.5278 26.5426 31.5278 24.6132V19.1777H30.5984V24.6132Z')
                .fill('#807F7F')
                .path('M38.7353 20.0877C38.0905 19.4384 37.1422 19.0859 36.2128 19.0859C34.5438 19.0859 33.7852 20.2176 33.7852 21.3863C33.7852 24.1875 38.0146 23.4455 38.0146 25.3933C38.0146 26.0612 37.5215 26.9888 36.2128 26.9888C35.4732 26.9888 34.6576 26.6919 34.1456 26.1168L33.5576 26.6919C34.2404 27.4154 35.2266 27.8236 36.2887 27.8236C38.1094 27.8236 38.9819 26.6177 38.9819 25.3748C38.9819 22.6663 34.7335 23.3713 34.7335 21.3677C34.7335 20.737 35.1508 19.9207 36.2887 19.9207C36.9715 19.9207 37.7301 20.2361 38.1474 20.6813L38.7353 20.0877Z')
                .fill('#807F7F')
                .path('M41.3145 27.7298H42.2628V19.1777H41.3145V27.7298Z')
                .fill('#807F7F')
                .path('M44.8428 19.1777V27.7298H45.7911V20.9215L51.2343 27.7298H51.8602V19.1777H50.9308V26.0046L45.4876 19.1777H44.8428Z')
                .fill('#807F7F')
                .path('M55.4258 19.9569H59.1052V19.1777H54.4775V27.7298H59.1621V26.9692H55.4258V23.8155H58.688V23.0364H55.4258V19.9569Z')
                .fill('#807F7F')
                .path('M66.1611 20.0877C65.5162 19.4384 64.568 19.0859 63.6386 19.0859C61.9696 19.0859 61.211 20.2176 61.211 21.3863C61.211 24.1875 65.4404 23.4455 65.4404 25.3933C65.4404 26.0612 64.9473 26.9888 63.6386 26.9888C62.899 26.9888 62.0834 26.6919 61.5713 26.1168L60.9834 26.6919C61.6662 27.4154 62.6524 27.8236 63.7145 27.8236C65.5352 27.8236 66.4076 26.6177 66.4076 25.3748C66.4076 22.6663 62.1593 23.3713 62.1593 21.3677C62.1593 20.737 62.5765 19.9207 63.7145 19.9207C64.3973 19.9207 65.1559 20.2361 65.5731 20.6813L66.1611 20.0877Z')
                .fill('#807F7F')
                .path('M73.2539 20.0877C72.609 19.4384 71.6607 19.0859 70.7314 19.0859C69.0624 19.0859 68.3038 20.2176 68.3038 21.3863C68.3038 24.1875 72.5332 23.4455 72.5332 25.3933C72.5332 26.0612 72.0401 26.9888 70.7314 26.9888C69.9917 26.9888 69.1762 26.6919 68.6641 26.1168L68.0762 26.6919C68.7589 27.4154 69.7452 27.8236 70.8073 27.8236C72.628 27.8236 73.5004 26.6177 73.5004 25.3748C73.5004 22.6663 69.2521 23.3713 69.2521 21.3677C69.2521 20.737 69.6693 19.9207 70.8073 19.9207C71.49 19.9207 72.2487 20.2361 72.6659 20.6813L73.2539 20.0877Z')
                .fill('#807F7F')
                .path('M18.5356 46.1933C18.5356 45.4225 19.0528 44.8926 19.7917 44.8926C20.1857 44.8926 20.5059 45.013 20.7276 45.2539L21.097 44.8926C20.7768 44.5794 20.3089 44.3867 19.7671 44.3867C18.6834 44.3867 17.9199 45.1094 17.9199 46.1933C17.9199 47.2532 18.6588 47.9999 19.7671 47.9999C20.3089 47.9999 20.7768 47.8072 21.097 47.4941L20.7276 47.1328C20.5059 47.3495 20.1857 47.4941 19.7917 47.4941C19.0528 47.4941 18.5356 46.9641 18.5356 46.1933Z')
                .fill('#808080')
                .path('M22.2624 44.8817H23.7185V44.3867H21.627V47.9999H23.745V47.5297H22.2624V46.4408H23.5597V45.9458H22.2624V44.8817Z')
                .fill('#808080')
                .path('M24.2744 44.3867V47.9999H24.8943V45.5251L27.0382 47.9999H27.4515V44.3867H26.8316V46.8615L24.6619 44.3867H24.2744Z')
                .fill('#808080')
                .path('M29.9428 44.8817H31.1575V44.3867H27.9805V44.8817H29.1952V47.9999H29.9428V44.8817Z')
                .fill('#808080')
                .path('M32.2964 44.8817H33.7791V44.3867H31.6875V47.9999H33.8055V47.5297H32.2964V46.4408H33.5937V45.9458H32.2964V44.8817Z')
                .fill('#808080')
                .path('M36.2531 47.9999H36.9825L36.037 46.4408C36.4962 46.2923 36.7934 45.9458 36.7934 45.4509C36.7934 44.8074 36.3071 44.3867 35.5507 44.3867H34.335V47.9999H35.0104V46.515H35.4156L36.2531 47.9999ZM36.145 45.4509C36.145 45.7726 35.9019 45.9953 35.5777 45.9953H35.0374V44.8817H35.5777C35.9019 44.9064 36.145 45.1292 36.145 45.4509Z')
                .fill('#808080');
            doc.end();

            var stream = doc.pipe(blobStream());

            stream.on('finish', () => {
              if (window.navigator.userAgent.indexOf('Trident') > -1) {
                this.saveData(stream.toBlob('application/pdf'), 'business-design.pdf');
              } else {
                this.adsBlocked(blocked => {
                  if (!blocked) {
                    let windowReference = window.open();
                    let pdfURL = stream.toBlobURL("application/pdf");
                    windowReference.target = '_blank';
                    windowReference.location = pdfURL;

                  } else {
                    this.saveData(stream.toBlob('application/pdf'), 'business-design.pdf');
                  }
                })
              }

              this.pdfSettingsModal = false;
              this.generateInProgress = false;
            });
          }
        }
      },

		},
	}
</script>