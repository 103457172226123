<template>
	<div class="main-wrapper main-wrapper--stars" v-show="userName">
		<div class="dashboard-wrapper">
			<h2 class="orange">{{ 'Вітаємо, ' + userName + '!'}}</h2>
			<p class="info-text">Ці тести допоможуть дізнатись більше про вашу компанію та структурувати інформацію, що неодмінно сприятиме зростанню вашого бізнесу.</p>

      <div class="dashboard-card-wrapper">
        <p class="info-text">Оберіть тест</p>
        <template v-if="!isTablet" >
        <router-link class="dashboard-card dashboard-card--bd" :to="{ name: 'about-business-design' }">
          <span class="card-subtitle">ТВІЙ БІЗНЕС</span>
          <span class="card-title">ДИЗАЙН</span>
           <div class="card-hover button orange-btn">Старт</div>

        </router-link>
        <router-link class="dashboard-card dashboard-card--am" :to="{ name: 'about-asymmetric-model' }">
          <span class="card-subtitle">Твоя асиметрична</span>
          <span class="card-title">модель</span>
          <div class="card-hover button orange-btn">Старт</div>
        </router-link>
        </template>


      <swiper v-if="isTablet" ref="mySwiper" :options="swiperOptions" class="dashboard-slider" @transitionStart="sliderFix">
        <swiper-slide>
          <router-link class="dashboard-card dashboard-card--bd" :to="{ name: 'about-business-design' }">
            <span class="card-subtitle">ТВІЙ БІЗНЕС</span>
            <span class="card-title">ДИЗАЙН</span>
          </router-link>
        </swiper-slide>
        <swiper-slide>
          <router-link class="dashboard-card dashboard-card--am" :to="{ name: 'about-asymmetric-model' }">
            <span class="card-subtitle">Твоя асиметрична</span>
            <span class="card-title">модель</span>
          </router-link>
        </swiper-slide>
        <swiper-slide></swiper-slide>
      </swiper>
      </div>
		</div>
	</div>


</template>

<script>
	import { mapGetters } from 'vuex';
	import { mixins } from '@/mixins';

	export default {
		data() {
			return {
        swiperOptions: {
          slidesPerView: 1.55,
          breakpoints: {
            577:{
              slidesPerView: 1.55,
            },
            450:{
              slidesPerView: 1.8,
            },
            375:{
              slidesPerView: 1.5,
            },
            321: {
              slidesPerView: 1.4,
            },
            320: {
              slidesPerView: 1.1,
            }
          }
        }
			}
		},
		mixins: [mixins],
		components: {
		},
		computed: {
			...mapGetters({
				user: 'user',
				loggedIn: 'loggedIn'
			}),
			userName() {
				if ( !this.user.nickname ) {
					return '';
				}
				return this.user.nickname.split(' ')[0];
			}
		},
		beforeMount() {
			if ( this.loggedIn ) {
    			this.$store.dispatch('results', false);
                return;
            }
            this.$store.dispatch('logged', true);
            this.$store.dispatch('getUserData')
            .catch(e => {
                this.$router.push({ name: 'welcome' })
            });

			this.$store.dispatch('results', false);
		},
		methods: {
      sliderFix() {
          const swiper = this.$refs.mySwiper.$swiper;
          if (swiper.activeIndex === swiper.slides.length - 1) {
            swiper.slideTo(swiper.slides.length - 2, 500, false)
          }
      }
		}
	}
</script>