<template>
	<div class="main-wrapper main-wrapper--stars" v-if="description && description.full">
		<div class="about-wrapper">
			<h2 class="orange uppercase">Твій Бізнес Дизайн</h2>
			<div class="about-text" v-if="!isMobile && description.full" v-html="description.full">
      </div>
      <div class="about-text" v-if="isMobile && description.short" v-html="description.short">
      </div>
      <router-link  class="card-hover button orange-btn" :to="{ name: 'instruction-business-design' }">
        Далі
      </router-link>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { mixins } from '@/mixins';

	export default {
		mixins: [mixins],
		components: {
		},
		computed: {
			...mapGetters({
				user: 'user',
				loggedIn: 'loggedIn',
        description: 'description'
			}),
		},
		beforeMount() {
            this.$store.dispatch('logged', true);
            this.$store.dispatch('getUserData')
                .then(() => {
                  this.$store.dispatch('getBdQuestions')
                })
            .catch(e => {
                this.$router.push({ name: 'welcome' })
            })
		},
	}
</script>