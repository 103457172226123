export const quiz = {
    data() {
        return {}
        },

    methods: {
        isQuestionAnswered(q_index, section, answers){
            if(answers.length){
                let id = answers.findIndex(el => el.question_id === section.question_id),
                    answer = answers[id].answers_array[q_index];
                return answer? answer.value : false;
            }
        },
        answersFilled(question_id, answers) {
            if (answers && answers[question_id]) {
                const currentQuestion = answers[question_id].answers_array;
                if (currentQuestion) {
                    let counter = 0;
                    for (const prop in currentQuestion) {
                        if(currentQuestion[prop].value.length){
                            counter++
                        }
                    }
                    return currentQuestion.length === counter;
                }
            }
        },
    }
};