import api from '../../api'
import * as types from '../mutation-types'

// initial state
const state = {
  data: {
    questions: [],
    brands: [],
    questionsAM: [],
    brandsAM: [],
    description: [],
    instruction: [],
    descriptionAM: [],
  }
}

// getters
const getters = {
  questions: state => state.data.questions,
  brands: state => state.data.brands,
  description: state => state.data.description,
  instruction: state => state.data.instruction,

  questionsAM: state => state.data.questionsAM,
  brandsAM: state => state.data.brandsAM,
  descriptionAM: state => state.data.descriptionAM,
 };

// actions
const actions = {

  getBdQuestions({ dispatch, commit }){
    return new Promise((resolve, reject) => {
      api.getData('questions', data => {
        commit(types.STORE_DATA, { data });
        resolve();
      })
    })
  },

  getAmQuestions({ dispatch, commit }){
    return new Promise((resolve, reject) => {
      api.getData('questionsAM', data => {
        commit(types.STORE_DATA, { data });
        resolve();
      })
    })
  },

  storeData({ commit }, userData) {
    return new Promise((resolve, reject) => {
      api.storeData(userData, resp => {
      })
    })
  },
  updateData({ commit }, userData) {
    return new Promise((resolve, reject) => {
      api.updateData(userData, resp => {
      })
    })
  },

  checkPermission({ dispatch, commit }, query) {
    return new Promise((resolve, reject) => {
      api.getData(query, data => {
        if ( data ) {
          resolve()
        } else {
          reject()
        }
      })
    })
  },

  storeUserData({ commit, dispatch }, data) {
    let obj = { userAnswers: data.userAnswers }
    if ( data.userSection ) {
      obj.userSection = data.userSection;
    }
    if ( data.userSectionAM ) {
      obj.userSectionAM = data.userSectionAM;
    }
    if ( data.userQuestion ) {
      obj.userQuestion = data.userQuestion;
    }
    if ( data.userQuestionAM ) {
      obj.userQuestionAM = data.userQuestionAM;
    }
    return new Promise((resolve, reject) => {
      dispatch('checkPermission', data.query)
        .then(() => {
          commit('USER_DATA_UPDATED', true);
          dispatch('updateData', { obj: obj, query: data.query });
          resolve();
        })
        .catch(() => {
          commit('USER_DATA_STORED', true);
          dispatch('storeData', { obj: obj, query: data.query });
          resolve();
        })
    })
  },
};

// mutations
const mutations = {
  [types.STORE_DATA] (state, { data }) {
    state.data = Object.assign({}, data)
  },
  [types.USER_DATA_STORED] (state, val) {
    state.stored = val
  },
  [types.USER_DATA_UPDATED] (state, val) {
    state.updated = val
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}