<template>
  <div class="main-wrapper main-wrapper--stars" v-if="instruction">
    <div class="about-wrapper">
      <h2 class="orange uppercase" v-if="instruction.title" v-html="instruction.title"></h2>
      <div class="about-text"  v-if="instruction.content" v-html="instruction.content">
      </div>
      <router-link  class="card-hover button orange-btn" :to="{ name: 'business-design' }">
        Старт
      </router-link>
    </div>
  </div>


</template>

<script>
import { mapGetters } from 'vuex';
import { mixins } from '@/mixins';

export default {
  mixins: [mixins],
  components: {
  },
  computed: {
    ...mapGetters({
      user: 'user',
      loggedIn: 'loggedIn',
      instruction: 'instruction'
    }),
  },
  beforeMount() {
    this.$store.dispatch('logged', true);
    this.$store.dispatch('getUserData')
        .then(() => {
          this.$store.dispatch('getBdQuestions')
        })
        .catch(e => {
          this.$router.push({ name: 'welcome' })
        })
  },
}
</script>