<template>
  <div class="results-wrapper" v-if="sections && answers.length" :class="pdfSettingsModal? 'opened-modal': ''">

    <template v-for="(section, sIndex) in sections">
      <div class="results-item" :key="'section'+sIndex"
           :class="['am-' + (sIndex+1), openedMobile[sIndex] ? 'opened' : 'closed']">
        <div class="result-item-index" v-if="!isMobile">
          {{ '0' + (sIndex + 1) }}
        </div>
        <div class="results-header">
          <h3 class="results-title uppercase" :class="isMobile? '': 'orange'" @click="openAnswers(sIndex)">
            {{ section.question_title }}</h3>
          <svg v-if="isMobile" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L7 7L1 0.999996" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>

        <transition name="fade">
          <div class="results-content" v-if="!isMobile || isMobile && openedMobile[sIndex]">
            <div class="sub-question" :key="'question' + qIndex" v-for="(question, qIndex) in section.sub_questions">
              <h4 class="white">{{ question }}</h4>
              <textarea-autosize :maxlength="maxSymbols" placeholder="Введіть вашу відповідь"
                                 @change.native="editAnswer()"
                                 :class="answers[sIndex].answers_array[qIndex].value === ''? 'empty-answer' : ''"
                                 v-model="answers[sIndex].answers_array[qIndex].value">
              </textarea-autosize>
            </div>

          </div>
        </transition>
      </div>
    </template>

    <div class="results-btn" @click="openModal()" v-if="!isMobile">
      <span>Моя<br>модель</span>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M22 20V16H20V20H4V16H2V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20Z" fill="white"/>
        <path d="M12 14.9294L12 3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.9531 11L11.9767 14.9765L8.00018 11" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>

    <template v-if="pdfSettingsModal && !isMobile">
      <transition name="fade">
        <div class="modal-overlay">
          <div class="modal-cross" @click="closePdfModal()">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 2L17 17M17 2L2 17" stroke-width="3" stroke-linecap="round"/>
            </svg>
          </div>
          <h1 class="modal-title">{{ modalTitle }}</h1>
          <div class="modal orange-modal modal--stretch">
            <ResultsButtons></ResultsButtons>
          </div>
        </div>
      </transition>
    </template>

    <ResultsButtons class="results-buttons-mobile" :modal-title="modalTitle" v-if="isMobile"></ResultsButtons>


    <template v-if="answersEmptyModal">
      <transition name="fade">
        <div class="modal-overlay success-modal">
          <div class="modal orange-modal">
            <p>Для отримання результатів тесту дайте відповіді на запитання</p>
            <button type="submit" @click="closeAnswersEmptyModal" class="button button-desktop">ок</button>
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {mixins} from '@/mixins';
import {results} from '@/results';
import {EventBus} from './bus.js';
import ResultsButtons from "@/components/parts/ResultsButtons";

const PDFDocument = require('pdfkit/js/pdfkit.standalone.js');
const blobStream = require('blob-stream');
export default {
  data() {
    return {
      activeSection: '',
      maxSymbols: 500,
      activeSubsection: null,
      buttonActive: false,
      answersEmptyModal: false,
      pdfSettingsModal: false,
      openedMobile: [],
      modalTitle: 'Надрукувати мою асиметричну модель',
      scale: 0,
    }
  },
  mixins: [mixins, results],
  computed: {
    ...mapGetters({
      sections: 'questionsAM',
      userId: 'userId',
      font: 'font',
      fontBold: 'fontBold',
      fontRoboto: 'fontRoboto',
      pdfParams: 'pdfParams',
    }),
  },
  components: {
    ResultsButtons: ResultsButtons
  },
  beforeMount() {
    this.$store.dispatch('logged', true);
    this.$store.dispatch('resultsAM', true);
    this.$store.dispatch('getUserData')
        .then(() => {
          this.$store.dispatch('getUserAnswers', 'usersAnswersAM')
              .then(data => {
                this.$store.dispatch('getAmQuestions')
                    .then(() => {
                      this.prepareData(data);

                      if (data.hasOwnProperty('userQuestionAM') && data.hasOwnProperty('userSectionAM')) {
                        this.activeSection = data.userSectionAM;
                        this.activeSubsection = data.userQuestionAM;
                      }
                      this.$store.dispatch('loadFont');
                      this.$store.dispatch('loadFontBold');
                      this.$store.dispatch('loadFontRoboto');

                      this.checkAnswersNumber();
                    })
              })
        });
    EventBus.$on('store', () => {
      const that = this;
      this.$store.dispatch('storeUserData', {
        userId: that.userId,
        userAnswers: that.answers,
        query: `/usersAnswersAM/${that.userId}`,
        userSectionAM: that.activeSection,
        userQuestionAM: that.activeSubsection
      })
          .then(() => {
            EventBus.$emit('dataStored');
          })
    });
  },
  mounted() {
    EventBus.$on('goToFirstQuestion', data => {
      this.goToQuestion()
    });

    EventBus.$on('closePdfModal', data => {
      this.closePdfModal();
    });

    EventBus.$on('pdf-generate-am', this.generatePDF);
  },
  beforeDestroy () {
    EventBus.$off('pdf-generate-am', this.generatePDF)
  },

  methods: {
    checkAnswersNumber() {
      let changed = false;

      for (let i = 0; i < this.sections.length; i++) {
        let subQuestions = this.sections[i].sub_questions, subQuestionsAnswers = this.answers[i].answers_array;

        if (subQuestionsAnswers.length < subQuestions.length) {
          for (let j = 0; j < subQuestions.length; j++) {
            if (!subQuestionsAnswers[j]) {
              changed = true;
              this.$set(this.answers[i].answers_array, j, {'value': ''})
            }
          }
        } else if (subQuestionsAnswers.length > subQuestions.length) {
          changed = true;
          let difference = subQuestionsAnswers.length - subQuestions.length, start = subQuestions.length - difference;
          this.answers[i].answers_array.splice(start, difference);
        }

      }
      if (changed) {
        EventBus.$emit('store');
      }
    },
    openAnswers(i) {
      this.$set(this.openedMobile, i, !this.openedMobile[i])
    },

    goToQuestion() {
      const that = this;
      this.$store.dispatch('storeUserData', {
        userId: that.userId,
        userAnswers: that.answers,
        query: `/usersAnswersAM/${that.userId}`,
        userSectionAM: 'q_1',
        userQuestionAM: 0
      })
          .then(() => {
            EventBus.$emit('dataStored');
          })
      this.$router.replace({name: 'asymmetric-model'}).catch(err => {
      });
    },

    openModal() {
      if (this.allAnswersEmpty(this.answers)) {
        this.answersEmptyModal = true;
        this.pdfSettingsModal = false;
      } else {
        this.pdfSettingsModal = true;
        this.answersEmptyModal = false;
      }
    },

    closePdfModal() {
      this.pdfSettingsModal = false;
    },

    closeAnswersEmptyModal() {
      this.answersEmptyModal = false;
    },

    editAnswer() {
      this.$store.dispatch('storeUserData', {
        userId: this.userId,
        userAnswers: this.answers,
        query: `/usersAnswersAM/${this.userId}`
      });
      EventBus.$emit('dataStored');
    },

    async generatePDF() {
        if (this.allAnswersEmpty(this.answers)) {
          this.answersEmptyModal = true;
        } else {
          this.scale = this.pdfParams.size === 'A3' ? 1.414 : 1;
          const doc = new PDFDocument({margin: 0, size: this.pdfParams.size, layout: 'landscape'});
          doc.registerFont('Proba', this.font);
          doc.registerFont('ProbaBold', this.fontBold);
          doc.registerFont('Roboto', this.fontRoboto);
          let bg = await this.toDataURL((this.pdfParams.color === 'color') ? 'pictures/pdf_color.png' : 'pictures/pdf.png');
          let bg2 = await this.toDataURL((this.pdfParams.color === 'color') ? 'pictures/pdf_color2.png' : 'pictures/pdf2.jpg');
          let qr1 = await this.toDataURL('pictures/pdf/Lvbs-qr.png');
          let qr2 = await this.toDataURL('pictures/pdf/bd-qr.png');
          let qr3 = await this.toDataURL('pictures/pdf/consulting-qr.png');
          let logo1 = await this.toDataURL((this.pdfParams.color === 'color') ? 'pictures/pdf/lvbs-logo.png' : 'pictures/pdf/lvbs-logo-bw.png')
          let logo2 = await this.toDataURL((this.pdfParams.color === 'color') ? 'pictures/pdf/bd-logo.png' : 'pictures/pdf/bd-logo-bw.png')
          let logo3 = await this.toDataURL((this.pdfParams.color === 'color') ? 'pictures/pdf/consulting-logo.png' : 'pictures/pdf/consulting-logo-bw.png')
          doc
              .image(bg2, 0, 0, {width: 842 * this.scale, height: 596 * this.scale});
          doc
              .image(bg, 0, 0, {width: 842 * this.scale, height: 596 * this.scale});

          doc
              .font('Proba')
              .fontSize(12 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0D0C0A')
              .fillOpacity((this.pdfParams.color === 'color') ? 0.9 : 0.5)
              .text('TВОЯ\nАСИМЕТРИЧНА\nМОДЕЛЬ', 34 * this.scale, 31 * this.scale, {
                width: 193 * this.scale,
                characterSpacing: .5,
                lineGap: 1.2

              })
              .font('Roboto')
              .fontSize(30 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0D0C0A')
              .fillOpacity((this.pdfParams.color === 'color') ? 0.9 : 0.9)
              .text(this.sections[0].question_title.toUpperCase(), 63 * this.scale, 109 * this.scale, {
                width: 220 * this.scale,
                characterSpacing: 2,
                fontWeight: 400,
              })

              .font('Proba')
              .fontSize(14 * this.scale)
              .text(`${this.prepareAnswers(this.answers[0])}`, 63 * this.scale, 157 * this.scale, {width: 193 * this.scale})
          doc
              .font('Roboto')
              .fontSize(14 * this.scale)
              .fillOpacity(1)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(this.sections[1].question_title.toUpperCase(), 457 * this.scale, 37 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale,
                characterSpacing: 1,
                lineGap: 1.2,
              })
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .font('Proba')
              .fontSize(9 * this.scale)
              .text(`${this.prepareAnswers(this.answers[1])}`, 457 * this.scale, 57 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale
              });
          doc
              .font('Roboto')
              .fontSize(14 * this.scale)
              .fillOpacity(1)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(this.sections[2].question_title.toUpperCase(), 311 * this.scale, 179 * this.scale, {
                width: 120 * this.scale,
                height: 106 * this.scale,
                characterSpacing: 1,
                lineGap: 1.2,
              })
              .font('Proba')
              .fontSize(9 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(`${this.prepareAnswers(this.answers[2])}`, 311 * this.scale, 200 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale
              });
          doc
              .font('Roboto')
              .fontSize(14 * this.scale)
              .fillOpacity(1)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(this.sections[3].question_title.toUpperCase(), 220 * this.scale, 343 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale,
                characterSpacing: 1,
                lineGap: 1.2,
              })
              .font('Proba')
              .fontSize(9 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(`${this.prepareAnswers(this.answers[3])}`, 220 * this.scale, 363 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale
              });
          doc
              .font('Roboto')
              .fontSize(14 * this.scale)
              .fillOpacity(1)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(this.sections[4].question_title.toUpperCase(), 650 * this.scale, 102 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale,
                characterSpacing: 1,
                lineGap: 1.2,
              })
              .font('Proba')
              .fontSize(9 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(`${this.prepareAnswers(this.answers[4])}`, 650 * this.scale, 121 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale
              });

          doc
              .font('Roboto')
              .fontSize(14 * this.scale)
              .fillOpacity(1)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(this.sections[5].question_title.toUpperCase(), 489 * this.scale, 225 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale,
                characterSpacing: 1,
                lineGap: 1.2,
              })
              .font('Proba')
              .fontSize(9 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(`${this.prepareAnswers(this.answers[5])}`, 489 * this.scale, 245 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale
              });

          doc
              .font('Roboto')
              .fontSize(14 * this.scale)
              .fillOpacity(1)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(this.sections[6].question_title.toUpperCase(), 416 * this.scale, 413 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale,
                characterSpacing: 1,
                lineGap: 1.2,
              })
              .font('Proba')
              .fontSize(9 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(`${this.prepareAnswers(this.answers[6])}`, 416 * this.scale, 432 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale
              });

          doc
              .font('Roboto')
              .fontSize(14 * this.scale)
              .fillOpacity(1)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(this.sections[7].question_title.toUpperCase(), 650 * this.scale, 369 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale,
                characterSpacing: 1,
                lineGap: 1.2,
              })
              .font('Proba')
              .fontSize(9 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text(`${this.prepareAnswers(this.answers[7])}`, 650 * this.scale, 388 * this.scale, {
                width: 140 * this.scale,
                height: 106 * this.scale
              });
          doc
              .font('Proba')
              .fontSize(9 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#FFFFFF' : '#0d0c0a')
              .text('Якщо Ти хочеш попрацювати з цим інстументом детальніше - звертайся до нас', 50 * this.scale, 475 * this.scale,
                  {
                    width: 184 * this.scale,
                    height: 28 * this.scale,
                    lineGap: 1.2,
                    fontWeight: 500,
                  }
              )

          doc
              .image(qr1, 50 * this.scale, 527 * this.scale, {
                width: 32 * this.scale,
                height: 32 * this.scale
              });
          doc
              .image(qr2, 169 * this.scale, 527 * this.scale, {
                width: 32 * this.scale,
                height: 32 * this.scale
              });
          doc
              .image(qr3, 293 * this.scale, 527 * this.scale, {
                width: 32 * this.scale,
                height: 32 * this.scale
              });
          doc
              .image(logo1, 95 * this.scale, 524.8 * this.scale, {
                width: 37 * this.scale,
                height: 37 * this.scale
              });
          doc
              .image(logo2, 210 * this.scale, 525 * this.scale, {
                width: 52 * this.scale,
                height: 35 * this.scale
              });
          doc
              .image(logo3, 337 * this.scale, 528.5 * this.scale, {
                width: 52 * this.scale,
                height: 27 * this.scale
              });


          doc.font('Proba')
              .fillOpacity(.7)
              .font('Proba')
              .fontSize(9 * this.scale)
              .fillColor((this.pdfParams.color === 'color') ? '#888B8D' : '#888B8D')
              .text('Designed & Developed by Bambuk', 660 * this.scale, 560 * this.scale, {
                    width: 177 * this.scale, link: 'https://bambukstudio.com/',
                    underline: false
                  },
              );
          doc.end();

          const stream = doc.pipe(blobStream());

          stream.on('finish', () => {
            if (window.navigator.userAgent.indexOf('Trident') > -1) {
              this.saveData(stream.toBlob('application/pdf'), 'asymmetric-model.pdf');
            } else {
              this.adsBlocked(blocked => {
                if (!blocked) {
                  let windowReference = window.open();
                  let pdfURL = stream.toBlobURL("application/pdf");
                  windowReference.target = '_blank';
                  windowReference.location = pdfURL;
                } else {
                  this.saveData(stream.toBlob('application/pdf'), 'asymmetric-model.pdf');
                }
              })
            }

            this.pdfSettingsModal = false;
          });
        }
      }
  },
}
</script>