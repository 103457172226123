import axios from "axios";
import * as firebase from "../firebase-config";
 const BASE = '';

function translate (error) {
    switch (error.code) {
        case 'auth/user-not-found':
            return { message: 'Немає запису користувача, що відповідає цьому email.' };
        case 'auth/wrong-password':
            return { message: 'Пароль недійсний.' };
        case 'auth/invalid-email':
            return { message: 'Email неправильно відформатований.' };
        case 'auth/email-already-in-use':
            return { message: 'Цей email вже зареєстрований.' };
        case 'auth/weak-password':
            return { message: 'Пароль повинен містити не менше 6 символів.' };
        default:
            return error;
    }
}

export default {
	getData(query, cb) {
		firebase.db.ref(query).once('value')
			.then(querySnapshot => {
				cb(querySnapshot.val());
			})
			.catch(e => {
				cb(null);
			})
	},
	updateData(data, cb) {
		firebase.db.ref(data.query).update(data.obj)
	},
	storeData(data, cb) {
		firebase.db.ref(data.query).set(data.obj)
	},
	signIn(data, cb) {
		firebase.auth.signInWithEmailAndPassword(data.username, data.password)
			.then( () => cb(data) )
			.catch(error => {
				cb(translate(error));
			})
	},
	signUp(data, cb) {
		firebase.auth.createUserWithEmailAndPassword(data.username, data.password)
			.then(resp => {
				if ( resp.user ) {
					cb(resp.user);
				}
			})
			.catch(err => {
				const error = err.message;
				cb(translate(err));
			})
    },
    restorePass(email, cb) {
        firebase.auth.sendPasswordResetEmail(email).then(() => {
                cb({});
            }).catch((error) => {
                cb(translate(error));
            });
    },
	signOut(cb) {
		firebase.auth.signOut().then(() => {
  			cb();
		}).catch(function(error) {
  			cb(error);
		});
	},
	createUser(data, cb) {
		firebase.db.ref(`users/${data.userId}`).once('value')
			.then(snapshot => {
				if ( !snapshot.exists() ) {
					firebase.db.ref(`users/${data.userId}`).set(data.user);
					cb();
				}
			})
	},
	getUserData(cb) {
		return new Promise((resolve, reject) => {
			firebase.auth.onAuthStateChanged(user => {
				if ( user ) {
					firebase.db.ref(`users/${user.uid}`).once('value')
						.then(snapshot => {
							const userInfo = Object.assign({}, snapshot.val(), { uid: user.uid });
							cb(userInfo);
							resolve(userInfo);
						});
				} else {
					resolve(null);
					this.signOut();
				}
			});
		})
	},
	loadFont(cb) {
		axios.get(BASE + '/fonts/Proba_Pro_Regular/ProbaPro-Regular.ttf', {responseType: 'arraybuffer', headers: {'Content-Type': 'font/ttf'}})
			.then(resp => {
				cb(resp.data);
			});
    },
    loadFontBold(cb) {
		axios.get(BASE + '/fonts/Proba_Pro_Bold/ProbaPro-Bold.ttf', {responseType: 'arraybuffer', headers: {'Content-Type': 'font/ttf'}})
			.then(resp => {
				cb(resp.data);
			});
	},
	loadFontRoboto(cb) {
		axios.get(BASE + '/fonts/Roboto/Roboto-Medium.ttf', {responseType: 'arraybuffer', headers: {'Content-Type': 'font/ttf'}})
			.then(resp => {
				cb(resp.data);
			});
	}
}