<template>
  <transition name="fade">
  <div class="tips modal-overlay" v-if="showTips">

    <div class="modal-cross" @click="closeTips()">
      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 2L17 17M17 2L2 17"  stroke-width="3" stroke-linecap="round"/>
      </svg>
    </div>

    <div class="tips-wrapper">
        <div class="brand" :key="'answer_' + b_index" v-for="(brand, b_index) in brandsAnswers" :class="b_index === activeBrandAnswer ? 'active' : ''">
        <div class="brand-name">
          <p class="h1">{{brand.name}}</p>
        </div>

        <div class="brand-answer h5 orange-scroll" v-html="brand.answer"></div>
      </div>

      <div class="tips-navigation" v-if="brandsAnswers.length > 1">
        <div class="navigation prev" @click="navigation('prev')">
          <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 16L3 9L10 2" stroke-width="3" stroke-linecap="round"/>
          </svg>

        </div>
        <span class="tips-active big-test"><span>{{ activeBrandAnswer + 1}}</span><span class="orange"> /{{brandsAnswers.length }}</span></span>
        <div class="navigation next" @click="navigation('next')">
          <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L9 9L2 16" stroke-width="3" stroke-linecap="round"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import {mixins} from "@/mixins";
import { EventBus } from '../bus.js';

export default {
  data() {
    return {
      activeBrandAnswer: 0,
      showTips: false,
    }
  },
  props: ['brandsAnswers'],
  mixins: [mixins],
  methods: {
    toggleTips() {
      this.showTips = !this.showTips;
    },
    closeTips() {
      this.toggleTips();
    	this.activeBrandAnswer = 0;
    },
    navigation(dir) {
      const len = this.brandsAnswers.length;
      if ( dir === 'next' ) {
        this.activeBrandAnswer = this.activeBrandAnswer + 1 >= len ? len - 1 : this.activeBrandAnswer + 1;
      } else {
        this.activeBrandAnswer = this.activeBrandAnswer - 1 < 0 ? 0 : this.activeBrandAnswer - 1;
      }
    },

  },
  watch: {
    showTips: function() {
      EventBus.$emit('showTips', this.showTips);
    }
  },
  created() {
    EventBus.$on("changeTips", ()=>{
     this.toggleTips();
    });
  }
}
</script>

