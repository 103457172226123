import api from '../../api'
import * as types from '../mutation-types'

// initial state
const state = {
  user: {
    company: null,
    nickname: '',
    phone: null,
    uid: '',
    username: null
  },
  userId: null,
  userLogout: false,
  loggedIn: false,

  results: false,
  resultsAM: false,

  font: null,
  fontBold: null,
  fontRoboto: null,
  pdfParams: {
    size: 'A3',
    color: 'black-white'
  },

}

// getters
const getters = {
  user: state => state.user,
  userId: state => state.userId,
  userLogout: state => state.userLogout,
  loggedIn: state => state.loggedIn,

  results: state => state.results,
  resultsAM: state => state.resultsAM,

  font: state => state.font,
  fontBold: state => state.fontBold,
  fontRoboto: state => state.fontRoboto,
  pdfParams: state => state.pdfParams,
}

// mutations
const mutations = {
  [types.STORE_USER] (state, user) {
    state.user = Object.assign({}, user);
  },
  [types.STORE_USER_ID] (state, uid) {
    state.userId = uid;
  },
  [types.STORE_FONT] (state, data) {
    state.font = data;
  },
  [types.STORE_FONT_BOLD] (state, data) {
    state.fontBold = data;
  },
  [types.STORE_FONT_ROBOTO] (state, data) {
    state.fontRoboto = data;
  },
  [types.STORE_USER_LOGOUT] (state, val) {
    state.userLogout = val;
  },
  [types.RESULTS_PAGE] (state, val) {
    state.results = val;
  },
  [types.RESULTS_PAGE_AM] (state, val) {
    state.resultsAM = val;
  },
  [types.USER_LOGGED_IN] (state, val) {
    state.loggedIn = val;
  },
  [types.STORE_PDF_PARAMS] (state, val) {
    state.pdfParams = val;
  },
}

// actions
const actions = {
  signIn ({ dispatch, commit }, data) {
    return new Promise((resolve, reject) => {
      api.signIn(data, (resp) => {
        if (resp && resp.username) {
            dispatch('getUserData').then(user => {
                dispatch('logged', true);
                resolve(data);
            })
        } else {
            reject(resp.message);
        }
      })
    })
  },
  signUp ({ dispatch, commit }, data) {
    return new Promise((resolve, reject) => {
      api.signUp(data, resp => {
        if ( resp.uid ) {
          let userData = Object.assign({}, { nickname: data.nickname, company: data.company, phone: data.phone, email: data.username })
          dispatch('createUser', { user: userData, userId: resp.uid })
            .then(() => {
              dispatch('getUserData')
                .then(user => {
                  dispatch('logged', true)
                  resolve(resp);
                });
            });
        } else {
          reject(resp.message);
        }
      })
    })
  },
  restorePass({ dispatch }, email) {
    return new Promise((resolve, reject) => {
        api.restorePass(email, resp => {
            if (resp.message) {
                reject(resp.message);
            } else {
                resolve();
            }
        });
    });
  },
  createUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      api.createUser(data, () => {
        resolve();
      })
    })
  },
  signOut({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      api.signOut(() => {
        localStorage.setItem('userId', '');
        dispatch('logged', false);
        resolve();
      })
    })
  },
  logged({ commit }, val) {
    commit(types.USER_LOGGED_IN, val);
  },
  results({ commit }, val) {
    commit(types.RESULTS_PAGE, val);
  },
  resultsAM({ commit }, val) {
    commit(types.RESULTS_PAGE_AM, val);
  },
  getUserData({ commit }) {
    return new Promise((resolve, reject) => {
      api.getUserData(data => {
        let userData = Object.assign({}, data);
        localStorage.setItem('userId', userData.uid);
        commit(types.STORE_USER_ID, userData.uid);
        commit(types.STORE_USER, userData);
        resolve(data);
      })
    })
  },
  getUserAnswers({ commit }, route) {
    const userId = localStorage.getItem('userId');
    return new Promise((resolve, reject) => {
      if ( userId ) {
        const data = `/${route}/${userId}`;
        api.getData(data, data => {
          if ( data ) {
            resolve(data);

          } else {
            resolve(null);
          }
        })
      } else {
        reject();
      }
    })
  },
  loadFont({ commit }) {
    return new Promise((resolve, reject) => {
      api.loadFont(data => {
        commit(types.STORE_FONT, data);
        resolve(data);
      })
    })
  },
  loadFontBold({ commit }) {
    return new Promise((resolve, reject) => {
      api.loadFontBold(data => {
        commit(types.STORE_FONT_BOLD, data);
        resolve(data);
      })
    })
  },
  loadFontRoboto({ commit }) {
    return new Promise((resolve, reject) => {
      api.loadFontRoboto(data => {
        commit(types.STORE_FONT_ROBOTO, data);
        resolve(data);
      })
    })
  },

  setPdfParams({commit}, obj){
    commit(types.STORE_PDF_PARAMS, {size: obj.size, color: obj.color});
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}