<template>
  <div class="section-final q_fin">
    <div class="section-wrapper">
    <div class="section-text">
      <h1 class="item-title">Вітаємо!</h1>
      <p class="info-text">Ви пройшли всі запитання. Далі у вас є можливість переглянути відповіді та при потребі відредагувати їх.</p>
      <router-link :to="{name: 'results-business-design'}" class="button">далі</router-link>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'section-final',
}
</script>