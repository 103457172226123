import { mapGetters } from 'vuex';

export const mixins = {
	data() {
		return {
			isMobile: false,
			isTablet: false,
			answers: [],
			isSubsection: false,
		}
	},
	computed: {
		...mapGetters({
			userId: 'userId'
		})
	},
	methods: {
	    togglePass(refs) {
			if ( refs.password ) {
				if( refs.password.type === 'password' ) {
					refs.password.type = "text";
				} else {
					refs.password.type = "password";
				}
			}
		},
		activeInput(ref, field, refs) {
			if ( field && field.length ) {
				return;
			}
			refs[ref].classList.toggle('active')
		},
		prepareData(data) {
			if (data) {
				this.answers = Object.assign([], data.userAnswers);
			} else {
				this.answers = Object.assign([], this.sections.map(el => { return { question_id: el.question_id, answers_array: el.sub_questions.map(el => { return { value: '' } }) } }));
			}
	    },

		handleResize() {
			this.isMobile = window.innerWidth <= 576;
			this.isTablet = window.innerWidth <= 768;
		},
	},

	prepareBrandAnswers(brands, activeSection, activeSubsection){
		let brandsAnswers = [];
		if (brands) {
			brands.map(brand => {
				for(let i = 0, len = brand.brand_answers.length; i < len; i++) {
						let current = brand.brand_answers[i];
						if ( current.question_id === activeSection && current.answers[activeSubsection - 1] ) {
						brandsAnswers.push({answer: current.answers[activeSubsection - 1], name: brand.brand_name});
					}
				}
			});
		}
		return brandsAnswers;
	},

	circleProgress(activeSection, activeSubsection, sections){
		const circleLength = 314;
		const sectionIndex = activeSection === 'q_fin' ? null : parseInt(activeSection.slice(-1));

		if ( sections && sectionIndex) {
			const circleSection = circleLength/sections.length;

			const slices = circleSection/(sections[sectionIndex - 1].sub_questions.length);
			return (circleLength - circleSection * (sectionIndex - 1) - slices * activeSubsection);
		} else {
			return circleLength;
		}
	},

	created() {
		window.addEventListener('resize', this.handleResize);
		this.handleResize();
	},
	destroyed() {
		window.removeEventListener('resize', this.handleResize);
	},
};
