<template>
	<div class="full-height full-height--padding">
		<h1 class="orange form-title">Увійдіть в свій акаунт</h1>
		<form class="login" @submit.prevent="login" autocomplete="off">
			<div class="form-fields">
			<div class="part" ref="email">
				<label>E-mail</label>
				<input required v-model="userData.username" type="text" placeholder="" @focus="activeInput('email', userData.username, $refs)" @blur="activeInput('email', userData.username, $refs)"/>
			</div>
			<div class="part password last" ref="pass">
				<label>Пароль(повинен містити 6 символів)</label>
				<input required v-model="userData.password" minlength="6" type="password" ref="password" placeholder="" @focus="activeInput('pass', userData.password, $refs)" @blur="activeInput('pass', userData.password, $refs)"/>
				<div class="toggle-pass" @click="togglePass($refs)"></div>
			</div>

			<p class="forgot-psw">Забули пароль?&nbsp;&nbsp;<span class="link-underscore ml10" @click="toggleState('activeForm', 'restorepass')">Відновити</span></p>

			<div class="part error" v-if="showError">
				<div class="error-response" v-html="errorMessage"></div>
			</div>
			</div>
			<div class="part bottom-buttons">
				<button type="submit" class="button">далі</button>
				<div class="forms-tab">
					Немає акаунта? <span class="link-underscore ml10" @click="toggleState('activeForm', 'signup')">Зареєструйтесь</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { mixins } from '../mixins';

	export default {
		data() {
			return {
				userData: {
					username: '',
					password: ''
				},
                showError: false,
                errorMessage: ''
			}
		},
		mixins: [mixins],
		computed: {
			...mapGetters({
				user: 'user'
			})
		},
		beforeMount() {

		},
		methods: {
			toggleState(state, value) {
				this.$emit('stateChange', state, value);
			},
			login() {
				this.$store.dispatch('signIn', this.userData)
					.then(() => {
                this.$router.push('dashboard');
						// this.$store.dispatch('getUserAnswers', 'usersAnswers')
						// 	.then(data => {
            //     this.$router.push('dashboard');
						// 		let isAllAnswered = data.userAnswers.every(answers => {
						// 			return answers.answers_array.every(answer => Boolean(answer.value));
						// 		});
            //
						// 			this.$router.push('dashboard');
						// 	})
						// 	.catch(e => {
						// 		this.$router.push('dashboard');
						// 	});
                    })
                    .catch(error => {
                        this.showError = true;
                        this.errorMessage = error;
                    });
			}
		}
	}
</script>