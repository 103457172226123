<template>
  <div class="section-final am-fin main-wrapper--stars">
    <div class="section-wrapper">
      <div class="section-text">
        <h1 class="item-title orange">Вітаємо!</h1>
        <p class="info-text">Ви пройшли всі питання.<br>
          Далі у Вас є можливість переглянути та при потребі редагувати ваші
          відповіді.</p>
        <router-link :to="{name: 'results-asymmetric-model'}" class="button">OK</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'section-final',
}
</script>