<template>
      <div class="section" v-show="isActive() && !activeSubsection > 0 || isMobile && !activeSubsection > 0" :class="[ {active: isActive(), }, getQuestionNumber()] ">
        <div class="section-wrapper" v-if="currentSection.question_id === activeSection && !isMobile">
          <div class="main-section">
            <div class="section-row">
              <h1 class="item-title orange">{{ currentSection.question_title }}</h1>
              <div class="section-tip">{{ currentSection.question_tip }}</div>
            </div>
            <div class="button next_btn" @click="goToQuestions">{{ 'далі' }}</div>
          </div>
        </div>

        <div class="section-wrapper-mob" :class="{'filled':  calcAnswered() > 0}" @click.capture.stop="navigateTo(currentSection.question_id, currentSection, firstNotAnswered())" v-if="isMobile">
          <div class="main-section">
            <div>
              <h3 class="item-title orange uppercase">{{ currentSection.question_title }}</h3>
              <p v-if="currentSection.question_tip_short && currentSection.question_tip_short.length > 0" class="section-tip">{{ currentSection.question_tip_short}}</p>
              <p v-else class="section-tip">{{ currentSection.question_tip}}</p>
            </div>

            <div class="question-pagination" v-if="userAnswers && calcAnswered() > 0">
              {{calcAnswered()}}
              <span class="orange">/{{currentSection.sub_questions.length}}</span>
            </div>

          </div>
        </div>
      </div>

</template>
<script>
import { mixins } from '@/mixins';
import { quiz } from '@/quiz';
  export default{
    props: ['currentSection', 'activeSection', 'activeSubsection', 'userAnswers', 'sectionPrefix'],
    mixins: [mixins, quiz],
    methods: {
      calcAnswered(){
        let sum = 0;
        this.currentSection.sub_questions.forEach((el, index) => {
          this.isQuestionAnswered(index, this.currentSection, this.userAnswers) ? sum++: sum;
        })
        return sum;
      },
      firstNotAnswered(){
        let i = 1;
        while (i < this.currentSection.sub_questions.length){
          if(!this.isQuestionAnswered(i, this.currentSection, this.userAnswers)){
            break;
          }
          i++;
        }
        return i;
      },
      goToQuestions() {
        this.$emit('goToQuestions');
      },
      isActive() {
        return this.currentSection.question_id === this.activeSection;
      },
      handleChange(id, section) {
        this.$emit('handleChange', id, section);
      },
      navigateTo(id, section, subsection) {
        this.$emit('navigateTo', id, section, subsection);
      },
      getQuestionNumber() {
        return this.sectionPrefix + '-' + this.currentSection.question_id.slice(-1);
      }
    }
  }
</script>