import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "./scss/app.scss";
import router from './router';
import TextareaAutosize from 'vue-textarea-autosize';
import pdfMake from 'pdfmake/build/pdfmake.min';
import VueMeta from 'vue-meta'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper.scss';

window.pdfMake = pdfMake;
 
Vue.use(TextareaAutosize);
Vue.use(VueAwesomeSwiper);
Vue.use(VueMeta)

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");