<template>
	<div class="main-wrapper" :class="activeForm? 'main-wrapper--stars-dark' : ''">
		<div class="welcome-wrapper" :class="isTablet && activeForm? 'no-overlay': ''">

      <div class="welcome-image" v-if="!activeForm">
        <img :src="'./pictures/welcome-planet.png'" alt="LvBS">
      </div>

			<div class="top-mobile-wrapper" v-if="isMobile">
				<div class="logo-wrapper" v-show="!activeForm">
          <a href="https://lvbs.com.ua/knowledge/uk/center5" target="_blank" class="business-design-logo">
            <svg width="60" height="40" viewBox="0 0 79 52" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.85">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2998 4.37751L12.3404 9.3415C16.1333 9.58267 18.4456 11.9742 18.7295 15.7324H28.1408C28.1408 7.37198 20.9606 -0.405616 12.2998 0.0164242V4.37751Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.84358 15.7124L5.33839 15.7325C5.54122 11.9744 8.01575 9.5225 11.8087 9.32153L11.7681 4.07617C4.75018 4.07617 0.227077 8.73871 0.409624 15.7124H2.84358Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.28497 16.3033L3.06445 16.2842C3.13666 21.1742 6.49451 24.4979 11.0439 24.6889L10.9716 22.3203C7.6499 22.072 5.55576 19.8371 5.28497 16.3033Z" fill="white"/>
                <path d="M19.165 44.391H22.6943C25.7164 44.391 27.765 42.5019 27.765 39.5074C27.765 36.5129 25.7164 34.6238 22.6943 34.6238H19.165V44.391ZM25.7773 39.5074C25.7773 41.497 24.4995 42.7832 22.674 42.7832H21.1325V36.2316H22.674C24.5197 36.2115 25.7773 37.5178 25.7773 39.5074Z" fill="white"/>
                <path d="M30.9291 36.1505H34.438V34.603H29.0225V44.3903H34.5192V42.8429H30.9291V40.2704H34.0121V38.7229H30.9291V36.1505Z" fill="white"/>
                <path d="M41.9835 35.7886C41.1519 34.9646 40.016 34.4622 38.7788 34.4622C36.8316 34.4622 35.7769 35.7484 35.7769 37.3561C35.7769 40.6521 40.2189 39.9286 40.2189 41.5765C40.2189 42.1393 39.7321 42.8427 38.7382 42.8427C37.9675 42.8427 37.1967 42.4005 36.6491 41.8579L35.4727 43.0235C36.426 43.9681 37.5821 44.5107 38.9208 44.5107C40.9896 44.5107 42.166 43.2044 42.166 41.5364C42.166 38.3409 37.7241 39.0041 37.7241 37.2959C37.7241 36.8135 38.0892 36.1302 38.9613 36.1302C39.6307 36.1302 40.3609 36.5322 40.7868 36.9341L41.9835 35.7886Z" fill="white"/>
                <path d="M43.6064 44.3903H45.4928V34.603H43.6064V44.3903Z" fill="white"/>
                <path d="M51.8403 40.4712H53.6049V42.4608C53.1181 42.7221 52.5705 42.8427 51.9417 42.8427C50.0554 42.8427 48.737 41.4761 48.737 39.4864C48.737 37.4968 50.0554 36.1302 51.9417 36.1302C52.9356 36.1302 53.7469 36.4719 54.3148 37.0346L55.5115 35.8489C54.6191 34.9646 53.3615 34.4622 51.8403 34.4622C48.8181 34.4622 46.7695 36.492 46.7695 39.4864C46.7695 42.4809 48.8181 44.5107 51.8403 44.5107C53.3412 44.5107 54.6191 43.9882 55.5115 43.124V38.9438H51.8403V40.4712Z" fill="white"/>
                <path d="M56.9121 34.603V44.3903H58.8187V38.2406L64.2343 44.3903H65.431V34.603H63.5244V40.7528L58.1088 34.603H56.9121Z" fill="white"/>
                <path d="M19.1035 30.0415H22.004C23.5861 30.0415 24.6611 29.0567 24.6611 27.4891C24.6611 26.3436 24.0323 25.4794 23.0587 25.1579C23.7483 24.7961 24.154 24.0927 24.154 23.1482C24.154 21.6811 23.1601 20.7566 21.6592 20.7566H19.1035V30.0415ZM23.6672 27.4288C23.6672 28.494 22.9776 29.1773 21.9634 29.1773H20.1379V25.7005H21.9634C22.9776 25.7005 23.6672 26.4039 23.6672 27.4288ZM23.1601 23.2486C23.1601 24.1932 22.5313 24.8363 21.5983 24.8363H20.1582V21.661H21.5983C22.5313 21.661 23.1601 22.284 23.1601 23.2486Z" fill="white"/>
                <path d="M33.1195 26.6646C33.1195 28.192 32.1053 29.237 30.6246 29.237C29.1643 29.237 28.1704 28.2121 28.1704 26.6646V20.7761H27.1562V26.6646C27.1562 28.7547 28.5558 30.1414 30.6246 30.1414C32.6935 30.1414 34.1133 28.7547 34.1133 26.6646V20.7761H33.1195V26.6646Z" fill="white"/>
                <path d="M41.8205 21.761C41.1308 21.0576 40.1167 20.6758 39.1228 20.6758C37.3379 20.6758 36.5266 21.9017 36.5266 23.1678C36.5266 26.2025 41.0497 25.3986 41.0497 27.5088C41.0497 28.2323 40.5223 29.2372 39.1228 29.2372C38.3318 29.2372 37.4596 28.9156 36.912 28.2926L36.2832 28.9156C37.0134 29.6994 38.0681 30.1415 39.204 30.1415C41.1511 30.1415 42.0841 28.8352 42.0841 27.4887C42.0841 24.5545 37.5407 25.3182 37.5407 23.1477C37.5407 22.4644 37.987 21.5802 39.204 21.5802C39.9341 21.5802 40.7455 21.9218 41.1917 22.4041L41.8205 21.761Z" fill="white"/>
                <path d="M44.5801 30.0409H45.5942V20.7761H44.5801V30.0409Z" fill="white"/>
                <path d="M48.3516 20.7761V30.0409H49.3657V22.6653L55.1869 30.0409H55.8563V20.7761H54.8624V28.1719L49.0412 20.7761H48.3516Z" fill="white"/>
                <path d="M59.6704 21.6202H63.6053V20.7761H58.6562V30.0409H63.6661V29.2169H59.6704V25.8004H63.1591V24.9563H59.6704V21.6202Z" fill="white"/>
                <path d="M71.1505 21.761C70.4609 21.0576 69.4468 20.6758 68.4529 20.6758C66.668 20.6758 65.8567 21.9017 65.8567 23.1678C65.8567 26.2025 70.3798 25.3986 70.3798 27.5088C70.3798 28.2323 69.8524 29.2372 68.4529 29.2372C67.6619 29.2372 66.7897 28.9156 66.2421 28.2926L65.6133 28.9156C66.3435 29.6994 67.3982 30.1415 68.534 30.1415C70.4812 30.1415 71.4142 28.8352 71.4142 27.4887C71.4142 24.5545 66.8708 25.3182 66.8708 23.1477C66.8708 22.4644 67.3171 21.5802 68.534 21.5802C69.2642 21.5802 70.0755 21.9218 70.5218 22.4041L71.1505 21.761Z" fill="white"/>
                <path d="M78.7365 21.761C78.0469 21.0576 77.0327 20.6758 76.0388 20.6758C74.2539 20.6758 73.4426 21.9017 73.4426 23.1678C73.4426 26.2025 77.9657 25.3986 77.9657 27.5088C77.9657 28.2323 77.4384 29.2372 76.0388 29.2372C75.2478 29.2372 74.3756 28.9156 73.828 28.2926L73.1992 28.9156C73.9294 29.6994 74.9841 30.1415 76.12 30.1415C78.0671 30.1415 79.0002 28.8352 79.0002 27.4887C79.0002 24.5545 74.4568 25.3182 74.4568 23.1477C74.4568 22.4644 74.903 21.5802 76.12 21.5802C76.8502 21.5802 77.6615 21.9218 78.1077 22.4041L78.7365 21.761Z" fill="white"/>
                <path d="M20.219 50.0429C20.219 49.2078 20.7721 48.6337 21.5623 48.6337C21.9837 48.6337 22.3261 48.7642 22.5632 49.0251L22.9582 48.6337C22.6158 48.2945 22.1154 48.0857 21.536 48.0857C20.377 48.0857 19.5605 48.8686 19.5605 50.0429C19.5605 51.191 20.3507 52 21.536 52C22.1154 52 22.6158 51.7912 22.9582 51.452L22.5632 51.0606C22.3261 51.2954 21.9837 51.452 21.5623 51.452C20.7721 51.452 20.219 50.8779 20.219 50.0429Z" fill="white"/>
                <path d="M24.204 48.6219H25.7612V48.0857H23.5244V52H25.7895V51.4906H24.204V50.311H25.5913V49.7747H24.204V48.6219Z" fill="white"/>
                <path d="M26.3555 48.0857V52H27.0184V49.319L29.3112 52H29.7532V48.0857H29.0902V50.7667L26.7698 48.0857H26.3555Z" fill="white"/>
                <path d="M32.4189 48.6219H33.718V48.0857H30.3203V48.6219H31.6194V52H32.4189V48.6219Z" fill="white"/>
                <path d="M34.9344 48.6219H36.52V48.0857H34.2832V52H36.5483V51.4906H34.9344V50.311H36.3218V49.7747H34.9344V48.6219Z" fill="white"/>
                <path d="M39.1666 52H39.9466L38.9354 50.311C39.4266 50.1501 39.7444 49.7747 39.7444 49.2385C39.7444 48.5415 39.2243 48.0857 38.4154 48.0857H37.1152V52H37.8375V50.3914H38.2709L39.1666 52ZM39.051 49.2385C39.051 49.5871 38.791 49.8284 38.4443 49.8284H37.8664V48.6219H38.4443C38.791 48.6487 39.051 48.89 39.051 49.2385Z" fill="white"/>
              </g>
            </svg>
          </a>
          <a href="https://lvbs.com.ua/" target="_blank" class="lvbs-logo">
            <svg width="43" height="43" viewBox="0 0 58 57" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.85" d="M28.6677 56.9992C44.5004 56.9992 57.3354 44.2483 57.3354 28.5192C57.3354 12.79 44.5004 0.0390625 28.6677 0.0390625C12.835 0.0390625 0 12.79 0 28.5192C0 44.2483 12.835 56.9992 28.6677 56.9992Z" fill="#FF8200"/>
              <path d="M45.5004 31.6565C45.5004 32.3293 45.3392 32.9059 45.0167 33.4505C44.6297 34.0913 44.0815 34.5398 43.4044 34.796C42.9529 34.9562 42.308 35.0523 41.534 35.0523H37.084V33.4185H41.3406C41.9855 33.4185 42.4692 33.3224 42.8562 33.1622C43.4366 32.9059 43.7268 32.4574 43.7268 31.8487C43.7268 31.3682 43.5656 30.9517 43.2431 30.5993C42.9206 30.2469 42.1467 29.7664 40.8891 29.1897C40.0829 28.8053 39.5347 28.549 39.18 28.3247C38.5673 27.9403 38.0836 27.5559 37.7934 27.1394C37.3742 26.5628 37.1485 25.922 37.1485 25.2493C37.1485 24.5445 37.3097 23.9678 37.6644 23.4552C38.0514 22.8786 38.5996 22.4942 39.2768 22.2699C39.7605 22.1097 40.3087 22.0457 40.9858 22.0457H44.8877V23.6795H40.8569C40.3732 23.6795 40.0184 23.7115 39.7605 23.7756C39.5025 23.8397 39.2768 24.0319 39.0833 24.3523C38.9543 24.5445 38.8898 24.7687 38.8898 25.025C38.8898 25.4095 38.9865 25.7298 39.18 26.0181C39.3413 26.2424 39.567 26.4346 39.9217 26.6589C40.2764 26.8831 40.8246 27.1714 41.5018 27.4918C42.3402 27.8762 42.9206 28.1966 43.2431 28.3888C43.8881 28.7732 44.404 29.1897 44.7265 29.6062C45.2747 30.2469 45.5004 30.9197 45.5004 31.6565Z" fill="white"/>
              <path d="M36.1815 31.3373C36.1815 32.3624 35.859 33.1954 35.2463 33.8681C34.5047 34.637 33.4083 35.0214 31.9571 35.0214H29.6031V33.3876H31.8604C32.5376 33.3876 33.0535 33.2915 33.4083 33.1313C34.0854 32.8109 34.4402 32.1702 34.4402 31.2412C34.4402 30.6004 34.2144 30.0558 33.7952 29.6714C33.3438 29.287 32.7633 29.0947 32.0539 29.0947H29.6353V27.4609H31.4089C32.0861 27.4609 32.6666 27.3007 33.1503 26.9483C33.634 26.628 33.8597 26.1474 33.8597 25.5067C33.8597 24.9301 33.6662 24.4495 33.2793 24.0651C32.8278 23.6166 32.2474 23.4244 31.5379 23.4244C30.8285 23.4244 30.248 23.6486 29.7966 24.0651C29.2484 24.5456 28.9904 25.2504 28.9904 26.1474V34.9573H27.249L27.2813 26.1154C27.2813 24.8339 27.6682 23.7768 28.4422 22.9759C29.2161 22.175 30.2158 21.7905 31.4089 21.7905C32.7633 21.7905 33.8275 22.175 34.6014 22.9438C35.3108 23.6486 35.6333 24.4816 35.6333 25.4426C35.6333 26.1154 35.5043 26.692 35.2463 27.1085C35.0529 27.4609 34.7304 27.7492 34.3112 27.9735C34.9239 28.2938 35.4076 28.7103 35.7301 29.287C36.0203 29.9597 36.1815 30.6004 36.1815 31.3373Z" fill="white"/>
              <path d="M25.959 27.5256L23.4115 34.061C23.2502 34.4454 23.1213 34.7017 23.0245 34.8298C22.7988 35.0861 22.5086 35.2143 22.0571 35.2143C21.7346 35.2143 21.4767 35.1502 21.3154 35.022C21.122 34.8939 20.9607 34.5735 20.735 34.093L18.1875 27.5577H19.9611L22.0249 33.0038L24.0887 27.5577H25.959V27.5256Z" fill="white"/>
              <path d="M18.8649 35.0202H16.3496C15.7691 35.0202 15.2854 34.9882 14.834 34.8921C14.3825 34.796 13.9633 34.6358 13.5763 34.3475C12.8991 33.899 12.4154 33.2582 12.1252 32.4573C11.9317 31.8807 11.835 31.2079 11.835 30.4711V22.0457H13.5763V30.4711C13.5763 30.9516 13.6086 31.3361 13.7053 31.6244C13.8343 32.1049 14.06 32.4894 14.3825 32.8097C14.6727 33.066 14.9952 33.2582 15.3822 33.3223C15.6079 33.3864 15.9304 33.3864 16.3173 33.3864H18.8649V35.0202Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9926 23.5198C24.5089 23.6159 24.0897 23.8082 23.735 24.1285C23.2835 24.545 23.0256 25.0576 22.9611 25.7624H21.2197C21.2842 24.6731 21.6712 23.7761 22.3806 23.0713C23.09 22.3665 23.9607 21.9501 24.9926 21.886V23.5198Z" fill="white"/>
            </svg>

          </a>
				</div>

				<div class="arrow-back" v-if="activeForm === 'login' || activeForm === 'signup' || activeForm === 'restorepass'">
                    <img @click="toggleState('activeForm')" src="../../public/img/arrow-icon-big.svg" alt="">
				</div>

			</div>

			<div class="message-wrapper" v-if="!activeForm">
					<h1 class="logo-text">
            <span class="logo-text-light">Дізнайся більше про</span>
            <span class="logo-text-bold">свій бізнес</span>
          </h1>

				<div class="buttons-wrapper">
					<div class="button orange-btn enter-btn" @click="toggleState('activeForm', 'login')"><span>вхід</span></div>
					<div class="button-link link-underscore" @click="toggleState('activeForm', 'signup')">реєстрація</div>
				</div>
			</div>
			<login-form v-show="activeForm === 'login'" @stateChange="toggleState">
			</login-form>
			<sign-up-form v-show="activeForm === 'signup'" @stateChange="toggleState">
			</sign-up-form>
			<restore-pass v-show="activeForm === 'restorepass'" @stateChange="toggleState">
			</restore-pass>


		</div>
	</div>
</template>

<script>
	import { mixins } from '../mixins';
	import loginForm from './loginForm';
	import signUpForm from './signUpForm';
	import restorePass from './restorePass';

	export default {
		data() {
			return {
				publicPath: process.env.BASE_URL,
				activeForm: null,
			}
		},
		mixins: [mixins],
		components: {
			loginForm: loginForm,
			signUpForm: signUpForm,
			restorePass: restorePass
		},
		computed: {
		},
		methods: {
			toggleState(state, value) {
				this[state] = value;
			},
		}
	}
</script>