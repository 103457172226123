import Firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import CONFIG from "../config.json";

export const app = Firebase.initializeApp({
	apiKey: CONFIG.apiKey,
	authDomain: CONFIG.authDomain,
	projectId: CONFIG.projectId,
	databaseURL: CONFIG.databaseURL
});
export const db = Firebase.database(app);
export const auth = Firebase.auth();
