<template>
      <div class="results-buttons">
        <h4 v-if="!isMobile" class="results-buttons-title h3">Оберіть параметри:</h4>
        <h1 v-if="isMobile && modalTitle" class="results-buttons-title">{{ modalTitle }}</h1>

        <div v-if="pdfParams" class="results-buttons-format">
          <div class="results-buttons-format__title gray">Формат</div>
          <label for="A3_sheet"><input type="radio" v-model="pdfSize" @change="changeParams" name="print_format_sheet" value="A3" id="A3_sheet" checked>
            <span>A3</span>
          </label>
          <label for="A4_sheet"><input type="radio" v-model="pdfSize" @change="changeParams" name="print_format_sheet" value="A4" id="A4_sheet">
            <span>A4</span>
          </label>
        </div>

        <div class="results-buttons-color">
          <div class="results-buttons-color__title gray">Колір друку</div>
          <label for="black-white_btn">
            <input type="radio" v-model="pdfColor" @change="changeParams" name="print_color_check" value="black-white" id="black-white_btn" checked>
            <span>Чорно-білий</span>
          </label>
          <label for="color_btn">
            <input type="radio" v-model="pdfColor" @change="changeParams" name="print_color_check" value="color" id="color_btn">
            <span>Кольоровий</span>
          </label>
        </div>

        <div v-if="!isMobile" class="button" @click="pdfGenerateCall">PDF</div>

        <div v-if="isMobile" class="results-btn" @click="pdfGenerateCall">
          <span>Моя<br>модель</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 20V16H20V20H4V16H2V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20Z" fill="white"/>
            <path d="M12 14.9294L12 3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.9531 11L11.9767 14.9765L8.00018 11" stroke="white" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
      </div>

</template>
<script>
import {mixins} from '@/mixins';
import {EventBus} from '../bus.js';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'results-buttons',
  data() {
    return{
      pdfSize: '',
      pdfColor: '',
      currentPath: this.$router.history.current.name,
    }
  },
  mixins: [mixins],
  props:['modalTitle'],
  computed: {
    ...mapGetters({
      results: 'results',
      pdfParams: 'pdfParams',
    }),
  },
  watch: {
    $route(to, from) {
      this.currentPath = to.name;
    },
  },

  methods: {
    ...mapActions(['setPdfParams']),
    pdfGenerateCall() {
      if (this.currentPath === 'results-business-design') {
        EventBus.$emit('pdf-generate-bd');
      } else if (this.currentPath === 'results-asymmetric-model') {
        EventBus.$emit('pdf-generate-am');
      }
    },

    changeParams(){
      if(this.pdfSize && this.pdfColor){
        this.$store.dispatch('setPdfParams', {
          size: this.pdfSize,
          color: this.pdfColor,
        });
      }
    }
  },
  beforeMount() {
    this.pdfSize = this.pdfParams.size;
    this.pdfColor = this.pdfParams.color;
  },
}
</script>