<template>
	<div class="progress-wrapper">
		<div class="progress-container">
			<div class="progress-index" :key="index" v-for="(question, index) in questions" :class="+activeSection === index + 1 ? 'active' : activeSection > index + 1 ? 'passed' : ''">
				<span class="dot" @click="goTo(index)" :class="+activeSection === index + 1 ? 'active' : ''">
          				<span class="index" @click="goTo(index)">{{'0' + (index+1)}}. {{question.question_title}}</span>
        </span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		computed: {
			activeSection: {
				get() {
					return this.currentSection.slice(this.currentSection.indexOf('_') + 1, this.currentSection.length);
				}
			},
		},
		props: ['questions', 'currentSection'],
		methods: {
			goTo(index) {
				const step = 'q_' + (index + 1);
				this.$emit('goToStep', step);
			}
		},
    created(){

    }
	}
</script>