import Vue from 'vue'
import Vuex from 'vuex'
import createPersist from 'vuex-localstorage'
import questions from './modules/questions'
import user from './modules/user'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

let localStorage = createPersist({
    namespace: 'Lvbs quiz',
    initialState: {},
    expires: 1.21e+9 // Two Weeks
})

export default new Vuex.Store({
  modules: {
  	questions,
  	user
  },
  strict: debug
})