<template>
	<div class="full-height full-height--register">
		<h1 class="orange form-title">{{ 'Створіть акаунт' }}</h1>
		<form class="login" @submit.prevent="login" autocomplete="off">
			<div class="form-fields">
			<div class="part" ref="nick">
				<label>Ім'я та прізвище</label>
				<input required v-model="userData.nickname" type="text" placeholder="" minlength="2" maxlength="30" @focus="activeInput('nick', userData.nickname, $refs)" @blur="activeInput('nick', userData.nickname, $refs)"/>
			</div>
			<div class="part" ref="company">
				<label>Назва компанії</label>
				<input required v-model="userData.company" type="text" placeholder="" @focus="activeInput('company', userData.company, $refs)" @blur="activeInput('company', userData.company, $refs)"/>
			</div>
			<div class="part" ref="phone">
				<label>Номер телефону</label>
				<input required v-model="userData.phone" type="text" minlength="6" placeholder="" @focus="activeInput('phone', userData.phone, $refs)" @blur="activeInput('phone', userData.phone, $refs)"/>
			</div>
			<div class="part" ref="email">
				<label>E-mail</label>
				<input required v-model="userData.username" type="email" placeholder="" @focus="activeInput('email', userData.username, $refs)" @blur="activeInput('email', userData.username, $refs)"/>
			</div>
			<div class="part password last" ref="pass">
				<label>Пароль (повинен містити 6 символів)</label>
				<input required v-model="userData.password" type="password" ref="password" minlength="6" placeholder="" @focus="activeInput('pass', userData.password, $refs)" @blur="activeInput('pass', userData.password, $refs)"/>
				<div class="toggle-pass" @click="togglePass($refs)"></div>
			</div>
			<div class="part error" v-if="showError">
				<div class="error-response" v-html="errorMessage"></div>
			</div>
			</div>
			<div class="part bottom-buttons">
				<button type="submit" class="button">далі</button>
				<div class="forms-tab">
					Вже зареєстровані?&nbsp;&nbsp;<span class="link-underscore ml10" @click="toggleState('activeForm', 'login')">Увійдіть</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import { mixins } from '../mixins';

	export default {
		data() {
			return {
				userData: {
					nickname: '',
					company: '',
					phone: '',
					username: '',
					password: ''
				},
				showError: false,
				errorMessage: ''
			}
		},
		mixins: [mixins],
		computed: {
		},
		beforeMount() {

		},
		methods: {
			toggleState(state, value) {
				this.$emit('stateChange', state, value);
			},
			login() {
				this.userData.username = this.userData.username.trim();
				this.$store.dispatch('signUp', this.userData)
					.then(() => {
						this.$router.push({ name: 'dashboard' })
					})
					.catch(e => {
						this.showError = true;
						this.errorMessage = e;
					})
			}
        },
        watch: {
            'userData.phone': function (val) {
                this.userData.phone = val.replace(/[^\+\ \-0-9()]/g, '');
            }
        }
	}
</script>