export const results = {
    data() {
        return {
            A3:  [841.89, 1190.55],
            A4: [595.28, 841.89]
        }
        },
    methods: {
        allAnswersEmpty(answers){
            let notEmptyAnswers = 0;
            for(let i = 0; i < answers.length; i++ ){
                if(this.prepareAnswers(answers[i]) !== ''){
                    notEmptyAnswers++;
                }
            }
            return notEmptyAnswers === 0;
        },
        prepareAnswers (answers) {
            let lastElem = answers.answers_array.length - 1;
            return answers.answers_array[lastElem].value
        },

        adsBlocked(callback){
            const testURL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

            const myInit = {
                method: 'HEAD',
                mode: 'no-cors'
            };

            const myRequest = new Request(testURL, myInit);

            fetch(myRequest).then(function(response) {
                return response;
            }).then(function(response) {
                callback(false)
            }).catch(function(e){
                callback(true)
            });
        },
        saveData(data, fileName) {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            return (function () {
                const url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            }());
        },

        toDataURL(url) {
            return fetch(url, {mode: "no-cors"})
                .then(response => response.blob())
                .then(blob => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(blob)
                }))
        },

    }
};