<template>
	<div class="full-height full-height--padding">
		<h1 class="form-title orange">Забули пароль?</h1>

		<form class="login" @submit.prevent="restore" autocomplete="off">
			<div class="form-fields">
			<p class="form-subtitle">Будь ласка, введіть вашу електронну адресу і ми надішлемо вам посилання для зміни паролю.</p>
			<div class="part" ref="email">
				<label>E-mail</label>
				<input required v-model="email" type="email" placeholder="" @focus="activeInput('email', email, $refs)" @blur="activeInput('email', email, $refs)"/>
			</div>
			<div class="part error" v-if="showError">
				<div class="error-response" v-html="errorMessage"></div>
			</div>
			</div>
			<div class="part bottom-buttons">
				<button type="submit" class="button">далі</button>
				<div class="forms-tab">
					Немає акаунта?&nbsp;&nbsp;<span class="link-underscore ml10" @click="toggleState('activeForm', 'signup')">Зареєструйтесь</span>
				</div>
			</div>
			<div class="modal-overlay" v-if="modalOpened">
        <div class="modal-cross" @click="closeSuccessModal">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 2L17 17M17 2L2 17" stroke-width="3" stroke-linecap="round"/>
          </svg>
        </div>
				<div class="modal orange-modal">
					<p>Посилання для відновлення паролю надіслано на Вашу електронну адресу</p>
					<button type="submit" @click="closeSuccessModal" class="button button-desktop">ок</button>
				</div>
			</div>
		</form>
	</div>

</template>

<script>
	import { mapGetters } from 'vuex';
	import { mixins } from '../mixins';

	export default {
		data() {
			return {
				email: '',
				showError: false,
				modalOpened: false,
				errorMessage: ''
			}
		},
		mixins: [mixins],
		computed: {
			...mapGetters({
				user: 'user'
			})
		},
		methods: {
			toggleState(state, value) {
				this.$emit('stateChange', state, value);
			},
			closeSuccessModal(){
				this.modalOpened = false;
				this.toggleState('activeForm', 'login');
			},
			restore() {
				this.$store.dispatch('restorePass', this.email)
					.then(() => {
						this.modalOpened = true;
						// alert('Посилання для відновлення паролю надіслано на ваш електронний адрес');
						//this.toggleState('activeForm', 'login');
					}).catch(error => {
						this.showError = true;
						this.errorMessage = error;
					});

			}
		}
	}
</script>