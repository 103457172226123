import Vue from 'vue'
import Router from 'vue-router'

// Components
import Welcome from '../components/Welcome'
import BusinessDesign from '../components/BusinessDesign'
import Dashboard from '../components/Dashboard'
import Results from '../components/Results'
import ResultsAM from '../components/ResultsAM'
import PageNotFound from '../components/PageNotFound'
import AsymmetricModel from '../components/AsymmetricModel'
import AboutBD from '../components/AboutBD'
import AboutAM from '../components/AboutAM'
import InstructionBD from '../components/InstructionBD'

Vue.use(Router)
const BASE = process.env.NODE_ENV ===  '';
const router = new Router({
  routes: [
    {
      path: '/',
      name: 'welcome',
      component: Welcome,
      beforeEnter: (to, from, next) => {
        if ( localStorage.getItem('userId') ) {
          next('/dashboard');
        } else {
          next()
        }
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      beforeEnter: (to, from, next) => {
        if ( localStorage.getItem('userId') ) {
          next();
        } else {
          next('/')
        }
      }
    },
    {
      path: '/about-bd',
      name: 'about-business-design',
      component: AboutBD,
      beforeEnter: (to, from, next) => {
        if ( localStorage.getItem('userId') ) {
          next();
        } else {
          next('/')
        }
      },
      props: true,
    },
    {
      path: '/instruction-bd',
      name: 'instruction-business-design',
      component: InstructionBD,
      beforeEnter: (to, from, next) => {
        if ( localStorage.getItem('userId') ) {
          next();
        } else {
          next('/')
        }
      },
      props: true,
    },
    {
      path: '/about-am',
      name: 'about-asymmetric-model',
      component: AboutAM,
      beforeEnter: (to, from, next) => {
        if ( localStorage.getItem('userId') ) {
          next();
        } else {
          next('/')
        }
      },
      props: true,
    },
    {
      path: '/bd',
      name: 'business-design',
      component: BusinessDesign,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('userId') ) {
          next();
        } else {
          next('/')
        }
      }
    },
    {
      path: '/am',
      name: 'asymmetric-model',
      component: AsymmetricModel,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('userId') ) {
          next();
        } else {
          next('/')
        }
      }
    },
    {
      path: '/results-bd',
      name: 'results-business-design',
      component: Results,
      beforeEnter: (to, from, next) => {
        if ( localStorage.getItem('userId') ) {
          next();
        } else {
          next('/')
        }
      }
    },
    {
      path: '/results-am',
      name: 'results-asymmetric-model',
      component: ResultsAM,
      beforeEnter: (to, from, next) => {
        if ( localStorage.getItem('userId') ) {
          next();
        } else {
          next('/')
        }
      }
    },
    { path: "*",
      name: 'page404',
      component: PageNotFound }
  ],
  mode: 'history',
  base: BASE
})

export default router;
