<template>
  <div class="question-wrapper-bg" :class="getQuestionNumber()">
  <div class="question-wrapper">
    <div class="question-wrapper__inner">

      <div class="question-section-title h2 orange" v-if="isMobile">
        {{currentSection.question_title}}
      </div>

      <div class="number-container" v-if="userAnswers && !isMobile">
          <div class="number-container-inner">
            <div class="dot"  v-for="(question, q_index) in this.currentSection.sub_questions" :key="'dot_' + q_index"
                 :class="['dot-' + q_index, {'passed': isQuestionAnswered(q_index, currentSection, userAnswers)}, q_index+1 === activeSubsection? 'active-white': '']"
                 @click="navigateSubQuestion(q_index + 1)">
            </div>
          </div>
      </div>

      <div class="number-container" v-if="userAnswers && isMobile">
        <div class="arrow-prev" @click="navigate('prev')">
          <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.321626 11.7852L10.2149 21.6752C10.649 22.1083 11.3524 22.1083 11.7876 21.6752C12.2217 21.2422 12.2217 20.5389 11.7876 20.1059L2.679 11.0006L11.7865 1.89531C12.2206 1.46229 12.2206 0.758963 11.7865 0.32485C11.3524 -0.108164 10.6479 -0.108164 10.2138 0.32485L0.320528 10.2149C-0.107006 10.6434 -0.107005 11.3578 0.321626 11.7852Z" fill="#807F7F"/>
          </svg>
        </div>

        <div class="number-container-overflow">
          <div class="number-container-inner" :style="`transform: translateX(${transformPagination}px);`">
            <div class="dot"  v-for="(question, q_index) in this.currentSection.sub_questions" :key="'dot_' + q_index"
                 :class="['dot-' + q_index, {'passed': isQuestionAnswered(q_index, currentSection, userAnswers)}, q_index+1 === activeSubsection? 'active-white': '']"
                 @click="navigateSubQuestion(q_index + 1)">
              <span>{{ q_index + 1 }}</span>
            </div>
          </div>
        </div>

        <div class="arrow-next" @click="navigate('next')">
          <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7915 10.2148L1.89823 0.324762C1.46412 -0.108254 0.760791 -0.108254 0.325583 0.324761C-0.108528 0.757776 -0.108528 1.46111 0.325583 1.89412L9.43416 10.9994L0.326676 20.1047C-0.107435 20.5377 -0.107435 21.241 0.326676 21.6751C0.760787 22.1082 1.46521 22.1082 1.89932 21.6752L11.7926 11.7851C12.2202 11.3566 12.2202 10.6422 11.7915 10.2148Z" fill="white"/>
          </svg>
        </div>
      </div>

      <div class="question-header">
        <div class="question-title">
          <span>{{ currentQuestion }}
          <span class="tips-icon" v-if="currentTips.length"  @click="openTips">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99958 0C4.47746 0 0 4.47746 0 9.99958C0 15.5217 4.47746 20 9.99958 20C15.5217 20 20 15.5217 20 9.99958C20 4.47746 15.5217 0 9.99958 0ZM12.0813 15.498C11.5666 15.7012 11.1568 15.8552 10.8495 15.9619C10.5431 16.0686 10.1867 16.1219 9.78116 16.1219C9.1581 16.1219 8.67302 15.9695 8.32762 15.6656C7.98222 15.3617 7.81037 14.9765 7.81037 14.5084C7.81037 14.3263 7.82307 14.1401 7.84847 13.9505C7.87471 13.7608 7.91619 13.5475 7.97291 13.3079L8.61714 11.0324C8.67386 10.814 8.72296 10.6066 8.7619 10.4135C8.80085 10.2188 8.81947 10.0402 8.81947 9.87767C8.81947 9.58815 8.75937 9.38497 8.64 9.27069C8.51894 9.1564 8.29122 9.10053 7.95175 9.10053C7.78582 9.10053 7.61481 9.12508 7.43958 9.17672C7.26603 9.23005 7.11534 9.27831 6.99175 9.32571L7.1619 8.62476C7.58349 8.45291 7.9873 8.30561 8.37249 8.1837C8.75767 8.06011 9.12169 7.99915 9.46455 7.99915C10.0834 7.99915 10.5608 8.14984 10.8969 8.44783C11.2313 8.74667 11.3998 9.13524 11.3998 9.6127C11.3998 9.71175 11.3879 9.88614 11.3651 10.135C11.3422 10.3848 11.299 10.6125 11.2364 10.8216L10.5956 13.0904C10.5431 13.2724 10.4965 13.4806 10.4542 13.7134C10.4127 13.9462 10.3924 14.124 10.3924 14.2434C10.3924 14.5448 10.4593 14.7505 10.5947 14.8597C10.7285 14.9689 10.963 15.0239 11.2948 15.0239C11.4514 15.0239 11.6267 14.996 11.8248 14.9418C12.0212 14.8876 12.1634 14.8394 12.2531 14.7979L12.0813 15.498ZM11.9678 6.2891C11.669 6.56677 11.3092 6.70561 10.8885 6.70561C10.4686 6.70561 10.1062 6.56677 9.80487 6.2891C9.50519 6.01143 9.35365 5.67365 9.35365 5.27915C9.35365 4.8855 9.50603 4.54688 9.80487 4.26667C10.1062 3.98561 10.4686 3.84593 10.8885 3.84593C11.3092 3.84593 11.6698 3.98561 11.9678 4.26667C12.2667 4.54688 12.4165 4.8855 12.4165 5.27915C12.4165 5.6745 12.2667 6.01143 11.9678 6.2891Z"/>
                </svg>
        </span>
            </span>
        </div>

      </div>
      <div class="area-wrapper">
        <textarea name="textarea" v-model="text" :maxlength="maxSymbols" :class="text===''? 'empty-answer':''" placeholder="Введіть вашу відповідь"></textarea>
      </div>
    </div>
    <span class="question-symbols">{{ symbolsLeft(text, maxSymbols) }}<span class="orange">/{{ maxSymbols }}</span></span>


    <svg v-if="!isMobile" id="progress-circle" class="progress-circle" width="632" height="636" viewBox="0 0 632 636" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="315" cx="315" cy="319" fill="#0D0C0A" fill-opacity="0.95" stroke="#807F7F" stroke-width="2" opacity="0.9"/>
      <circle r="315" cx="315" cy="318" stroke-width="2" class="orange-circle"
              :style="{'--to-width': (progressCircle + '%')}"
              stroke="#FF8200" stroke-miterlimit="2" fill="none"/>
    </svg>

    <div v-if="isMobile" class="next-question" @click="navigate('next')">
      <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6853 7.00043H1.08533" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.9711 1.80042L18.1711 7.00041L12.9711 12.2004" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </div>

  </div>
  </div>

</template>
<script>
import {mixins} from "@/mixins";
import {quiz} from "@/quiz";
import { EventBus } from '../bus.js';

export default {
  name: 'Sub-question',
  props: ['currentQuestion', 'currentSection', 'currentTips', 'activeSection', 'activeSubsection', 'userAnswers', 'progressCircle', 'sectionPrefix', 'currentIndex'],
  data() {
    return {
      maxSymbols: 500,
      transformPagination: 0,
    }
  },
  mixins: [mixins, quiz],
    computed: {
      text: {
        set(val) {
          this.$set(this.userAnswers[this.userAnswers.findIndex(el => el.question_id === this.activeSection)].answers_array[parseInt(this.activeSubsection) - 1], 'value', val);
        },
        get() {
          if ( this.userAnswers.length === 0 || this.userAnswers.findIndex(el => el.question_id === this.activeSection) === -1 ) {
            return '';
          }

          let activeSectionIndex = this.userAnswers.findIndex(el => el.question_id === this.activeSection),
          activeSubsectionIndex = parseInt(this.activeSubsection) === 0 ?  0 : parseInt(this.activeSubsection) - 1,
              answer =  this.userAnswers[activeSectionIndex].answers_array[activeSubsectionIndex];

          return answer? answer.value: '';
        }
      },
    },
  methods: {
    symbolsLeft(content, limit) {
      let symbols = content ? content.length : 0;
      if (symbols > limit) {
        symbols = limit;
      }
      return symbols;
    },
    paginationTransform(){
      let transform = 0, max = this.currentSection.sub_questions.length, coef = -60;
      if(max > 3){
        if(this.activeSubsection === 1) {
          transform = 0
        }else if(this.activeSubsection > max - 2){
          transform = coef * (max - 3);
        }else{
          transform = coef * (this.activeSubsection - 1);
        }
      }
      return transform;
    },
    openTips(){
      EventBus.$emit('changeTips');
    },
    navigate(direction){
      this.$emit('navigate', direction);
    },
    navigateSubQuestion(index){
      this.$emit('navigateSubQuestion', index);
    },
    getQuestionNumber() {
      return this.sectionPrefix + '-' + this.currentSection.question_id.slice(-1);
    },
  },
  watch: {
    activeSubsection: function() {
      this.transformPagination = this.paginationTransform();
    },
  },

}
</script>