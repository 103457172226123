<template>
  <div class="main-wrapper main-wrapper--stars">
    <div class="content-wrapper" v-show="!isLoading">
      <progress-bar class="uppercase" :class="activeSubsection ? 'active-subsection': ''" :questions="sections" :currentSection="activeSection" v-if="activeSection !== 'q_fin' && !isMobile" @goToStep="handleChange"></progress-bar>

      <template
          v-for="section in sections">

        <QuestionGeneral v-bind:key="section.question_id" :current-section="section" :active-subsection="activeSubsection" :active-section="activeSection"
                         :user-answers="answers" section-prefix="am"
                         @goToQuestions="goToQuestions" @handleChange="handleChange" @navigateTo="navigateTo">
        </QuestionGeneral>

        <SubQuestion v-bind:key="section.question_id + q_index" v-for="(question, q_index) in section.sub_questions"
                     :class="{ active: activeSubsection === q_index + 1 &&  section.question_id === activeSection }"
                     :current-section="section" :current-question="question" :current-tips="brandsAnswers" :active-section="activeSection"
                     :active-subsection="activeSubsection" :user-answers="answers" :progress-circle="progress" section-prefix="am"
                     @navigate='navigate' @navigateSubQuestion="navigateSubQuestion">
        </SubQuestion>

      </template>

      <div class="navigation-wrapper"
           v-if="activeSubsection !== 0 && activeSection !== 'q_fin' && !isMobile">
        <div class="prev-button" @click="navigate('prev')">
          <svg width="18" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 16L3 9L10 2" stroke-width="3" stroke-linecap="round"/>
          </svg>

        </div>
        <div class="next-button" @click="navigate('next')">
          <svg width="18" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 16L3 9L10 2" stroke-width="3" stroke-linecap="round"/>
          </svg>
        </div>

      </div>
      <SectionFinal v-if="activeSection === 'q_fin'"></SectionFinal>
    </div>

    <Tips :brands-answers="brandsAnswers"></Tips>


  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixins } from '@/mixins';
import { EventBus } from './bus.js';
import progressBar from './parts/Progress';
import SectionFinal from './parts/SectionFinalAM';
import QuestionGeneral from './parts/QuestionGeneral';
import SubQuestion from './parts/SubQuestion';
import Tips from './parts/Tooltips';

export default {
  data() {
    return {
      activeSection: 'q_1',
      activeSubsection: 0,
      isLoading: true,
    }
  },
  mixins: [mixins],
  name: "asymmetric-model",
  components: {
    progressBar: progressBar,
    SectionFinal: SectionFinal,
    QuestionGeneral: QuestionGeneral,
    SubQuestion: SubQuestion,
    Tips: Tips,
  },
  beforeMount() {
    this.$store.dispatch('logged', true);
    this.$store.dispatch('getUserData')
        .then(() => {
          this.$store.dispatch('getUserAnswers', 'usersAnswersAM')
              .then(data => {
                this.$store.dispatch('getAmQuestions')
                    .then(() => {
                      this.prepareData(data);

                      if (data && data.hasOwnProperty('userQuestionAM') && data.hasOwnProperty('userSectionAM')) {
                        this.activeSection = data.userSectionAM;

                        if (this.activeSection === 'q_fin') {
                          this.activeSubsection = 1;
                        }
                      }

                      this.isLoading = false;
                    })
                    .catch(e => {
                      this.isLoading = false;
                    });
              })
              .catch(e => {
                this.isLoading = false;
              });
        })
        .catch(e => {
          this.$router.push({ name: 'welcome' })
        });

    EventBus.$on('store', () => {
      const that = this;
      this.$store.dispatch('storeUserData', {
        userId: that.userId,
        userAnswers: that.answers,
        query: `/usersAnswersAM/${that.userId}`,
        userSectionAM: that.activeSection,
        userQuestionAM: that.activeSubsection
      })
          .then(() => {
            EventBus.$emit('dataStored');
          })
    });
  },
  computed: {
    ...mapGetters({
      userId: 'userId',
      sections: 'questionsAM',
      brands: 'brandsAM',
      user: 'user'
    }),
    brandsAnswers: {
      get() {
        return mixins.prepareBrandAnswers(this.brands, this.activeSection, this.activeSubsection);
      }
    },

    progress: {
      get() {
        return mixins.circleProgress(this.activeSection, this.activeSubsection, this.sections)
      }
    },
  },

  methods: {
    backToQuestions() {
      this.activeSubsection = 0;
    },

    goToQuestions() {
      this.activeSubsection += 1;
    },

    navigateTo(step, section, subsection_index) {
      this.activeSection = step;
      if (section) {
        this.activeSubsection = subsection_index;
      }
    },

    navigateSubQuestion(index){
      this.activeSubsection = index;
    },

    handleChange(step, section) {
      this.activeSection = step;
      if (section) {
        this.activeSubsection = 1;
      } else {
        this.activeSubsection = 0;
      }
      this.$store.dispatch('storeUserData', { userId: this.userId, userAnswers: this.answers, query: `/usersAnswersAM/${this.userId}`, userSectionAM: this.activeSection, userQuestionAM: this.activeSubsection + '' });
    },

    navigate(direction){
      const currentStep = this.sections.findIndex(el => el.question_id === this.activeSection),
          currentIndex = parseInt(this.activeSection.slice(-1));

      if ( direction === 'next' ) {
        if ( currentStep !== -1 && currentStep !== this.sections.length - 1 && this.activeSubsection === this.sections[currentStep].sub_questions.length ) {
          this.activeSection = this.activeSection.slice(0, 2) + (currentIndex + 1);
          this.activeSubsection = 0;
        } else {
          if ( currentStep === this.sections.length - 1 && parseInt(this.activeSubsection) === this.sections[currentStep].sub_questions.length) {
            this.activeSection = 'q_fin';
          } else {
            this.activeSubsection = parseInt(this.activeSubsection) + 1;
          }
        }
      } else {
        if ( currentStep !== -1 && currentStep !== 0 && this.activeSubsection === 0 ) {
          this.activeSection = this.activeSection.slice(0, 2) + (currentIndex - 1);
          this.activeSubsection = this.sections[currentStep - 1].sub_questions.length;
        } else {
          if ( this.activeSubsection === 0 ) {
            return;
          }
          this.activeSubsection = parseInt(this.activeSubsection) - 1;
        }
      }
      this.$store.dispatch('storeUserData', { userId: this.userId, userAnswers: this.answers, query: `/usersAnswersAM/${this.userId}`, userSectionAM: this.activeSection, userQuestionAM: this.activeSubsection + '' });
    },

  },
  watch: {
    activeSubsection: function() {
      EventBus.$emit('changeArrowVisibility', this.activeSubsection);
    }
  },
  created() {
    EventBus.$on("backToQuestions", ()=>{
      this.backToQuestions()
    });

    EventBus.$emit('changeArrowVisibility', this.activeSubsection);
  }
};
</script>