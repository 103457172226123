<template>
  <div class="main-wrapper error-404">
    <div class="error-404__content">
      <div class="error-404__title">
        <h1>404</h1>
        <div class="error-404__img"></div>
      </div>
      <h2 class="h1">houston, we have a problem</h2>
      <div class="error-404__excerpt"><p class="h4">We can’t seem to find what you’re looking for...
        Let us take you home</p>
      </div>
      <router-link :to="{ name:  'dashboard' }" class="button">ok</router-link>

    </div>
  </div>
</template>
<script>
export default {
  name: 'PageNotFound',
}
</script>